import { ListItemIcon, MenuItem, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { Link as RouterLink } from "react-router-dom";
import Language from "../language/Language";
import "./BackToTop.scss";

interface Props {
  route: string;
  onSelect: (language: Language) => void;
}

interface State {}

export default class LanguageMenuItems extends Component<Props, State> {
  shouldComponentUpdate(newProps: Props) {
    return newProps.route !== this.props.route;
  }

  render() {
    const route = this.props.route.substring(4);
    return LanguageMenuItems.getLanguages().map((language, i) => (
      <MenuItem
        key={i}
        component={RouterLink}
        to={`/${language.code}/${route}`}
        replace
        onClick={() => this.props.onSelect(language)}
      >
        <ListItemIcon>{language.flag}</ListItemIcon>
        <Typography>{language.name}</Typography>
      </MenuItem>
    ));
  }

  private static getLanguages() {
    return [Language.ENGLISH_US /*, Language.ENGLISH_UK*/, Language.POLISH];
  }
}
