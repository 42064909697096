import React, { Component } from "react";
import LocalizedStrings from "react-localization";
import {
  hasLanguageChanged,
  setLanguage,
} from "../../../component/ComponentUtils";
import { ProductInfo } from "../ProductCard";
import ProductCards from "../ProductCards";
import { AndroidPlatform, WebPlatform } from "../ProductPlatform";
import {
  TechFirebase,
  TechJava,
  TechKotlin,
  TechMaterialUi,
  TechPython,
  TechReact,
  TechSass,
  TechTypeScript,
} from "../Technology";

const localStr = new LocalizedStrings({
  en: {
    bluetoothConsoleDescription: `\
Bluetooth Console allows sending and receiving any kind of data between two electronic devices. The second device can be either an other smartphone or a tablet with this terminal installed, as well as a computer, and even a micro controller with a Bluetooth module.

Available data types in terminal include: plain text (in ASCII or UTF-8 encoding), floating-point numbers (of single or double precision) binary coded according to the IEEE 754 standard, and also integers with any (from 1 to 8) number of bytes that can be entered into the console as a decimal, binary or hexadecimal number.

This terminal allows you to save many settings of outgoing and incoming protocols in communication, which lets you to perform unit tests on the device's prototype.

If you're creating software for a micro controller, an embedded system or a robot that uses Bluetooth wireless communication, this Bluetooth Console will save you a lot of time in the testing phase of the prototype device.

The Bluetooth console can also be used as an unusual communicator, or a practical helper in understanding how data is transformed into a digital (binary) signal in the processors we deal with every day.`,
    bluetoothConsoleName: "Bluetooth Console",
    bluetoothConsoleShortDescription:
      "Terminal for Bluetooth module and transmission protocols in an electronic device",
    cutScheduleDescription: `\
Class schedule for students and lecturers from Częstochowa University of Technology (CUT, PCz).

Supported faculties of PCz:
* Faculty of Civil Engineering (Wydział Budownictwa)
* Faculty of Electrical Engineering (Wydział Elektryczny)
* Faculty of Mechanical Engineering and Computer Science (Wydział Inżynierii Mechanicznej i Informatyki, WIMiI)
* Faculty of Production Engineering and Materials Technology (Wydział Inżynierii Produkcji i Technologii Materiałów, WiP)
* Faculty of Management (Wydział Zarządzania)

Features:
* Latest student timetable available without constant internet connection
* Bookmark / favorite group list which makes checking your plan even faster!
* Student groups' schedules filter
* Teachers' class schedules and classrooms' locations (if provided)`,
    cutScheduleName: "CUT (PCz) Schedule",
    cutScheduleShortDescription:
      "Timetable for students and lecturers from Częstochowa University of Technology",
    fusiaDescription: `\
Hey! I'm Fusia, a relaxing puzzle game that uses a square board and colorful balls that you can move in 4 directions. Colored spheres can connect or absorb each other depending on their hue and game mode. As Fusia I have an RPG element, which is character development. After each played round, I give you experience points and stars. As you level up, you unlock new game modes and features. Due to my relaxing, calm and soothing nature I also play nice background music. I have a great memory and am good at math so I'm always ready to show your stats so you can impress your friends. Does it sound good for you? Will you make friends with Fusia?

🥼🔎 I develop myself all the time so that you and other players have the best time possible with me. And you can help with this. If you have any idea what I could improve or add, don't hesitate to drop me an email at contribute@fusia.kwezal.com 😊

FEATURES
🎨 Colorful, multicolored, prismatic, beautiful
😌 Relaxing, peaceful, restful, soothing
🎵 Calm background music
⚔️ Character development
📊 Statistics of rounds played and stars gained
🌞🌝 Light and dark theme - you can play during the day and at night

LANGUAGES
🇺🇸🇬🇧 English
🇵🇱 Polish

2 GAME MODES
🏔️ Paint It, White - Your task is to fill the game board with white balls
🌌 Dark Matter - You gain points for absorbing colorful balls

8 COLORS
⚪ White
⚫ Black
🔴 Red
🟢 Green
🔵 Blue
🟡 Yellow
🟣 Pink
🌊 Cyan`,
    fusiaShortDescription:
      "Peaceful logic board game about connecting colorful balls in various modes",
    myPressureDescription: `\
Handy pulse and blood pressure journal in which you can save your measurement results with optional note and keep track of your health condition regularly. The application is able to rate every entry according to the current medical standard. Correctness of these classifications is ensured by the medical consultant but remember that no application can be a substitute to doctor's appointment. We advise you to go to the specialist whenever you notice repeating high or low results.

My Pressure allows you to view the statistical charts drawn on the base of measurements of any given time range. Line chart can clearly represent increase or decrease of blood pressure or pulse, while pie chart can give you insight to the correct and departing from the norm results proportions.

Exporting journal to the PDF file gives you ability to send or print and show it to the doctor in the quick and easy way.

PERMISSIONS:
* External storage access - is required for the application so it can save PDF file in the right place on the data carrier.`,
    myPressureName: "My Pressure",
    myPressureShortDescription:
      "Pulse and blood pressure journal that allows you to monitor your health",
    synonymsDescription: ``,
    synonymsShortDescription: "Online Polish language thesaurus",
    quizAllDescription: `\
Test your knowledge by solving various quizzes yourself or with others! 3 different game modes, the ability to create your own quizzes and customization options will let you have fun the way you want!

The application contains 64 achievements. Get them all!

GAME MODES
* Single player - answer the questions yourself
* Hot phone - play with up to 10 friends on single device
* Bluetooth - challenge another player via Bluetooth

ENGLISH QUESTION PACKS
* World capitals
* Chemical symbols

Please keep in mind that this is a beta version, hence more functionalities, game modes and content will be added soon.

PERMISSIONS
- SD card access - needed for players' question packs to be downloaded and used in the game
- Network access - used by Google Play to sign in and browse player's achievements
- Bluetooth access - required to play with your friend through Bluetooth`,
    quizAllShortDescription:
      "Check yourself! Play with friends! Create your own quiz!",
  },
  pl: {
    bluetoothConsoleDescription: `\
Konsola Bluetooth jest testerem, który umożliwia wysyłanie i odbieranie dowolnych danych pomiędzy dwoma urządzeniami elektronicznymi. Drugie urządzenie może być zarówno innym smartfonem, czy tabletem z zainstalowanym terminalem, jak i komputerem, a nawet mikrokontrolerem posiadającym moduł Bluetooth.\nDostępne typy danych w terminalu obejmują: czysty tekst (w kodowaniu ASCII lub UTF-8), liczby zmiennoprzecinkowe (pojedynczej i podwójnej precyzji) kodowane binarnie zgodnie ze standardem IEEE 754, a także liczby całkowite o dowolnej (od 1 do 8) liczbie bajtów, możliwe do wprowadzenia w konsoli: dziesiętnie, dwójkowo lub szesnastkowo.

Terminal pozwala na zapisywanie wielu ustawień protokołów wychodzących i przychodzących obowiązujących w komunikacji, co umożliwia przeprowadzanie testów jednostkowych na prototypie urządzenia.

Jeśli tworzysz oprogramowanie mikrokontrolera, systemu wbudowanego, czy robota, który wykorzystuje komunikację bezprzewodową Bluetooth, to ta Konsola Bluetooth pozwoli Ci zaoszczędzić dużo czasu na etapie testowania prototypowanego urządzenia.

Konsola Bluetooth może być użyta również jako nietypowy komunikator, albo praktyczny pomocnik w zrozumieniu sposobu, w jaki dowolne dane przekształcane są na sygnał cyfrowy (binarny) w procesorach, z którymi mamy styczność na co dzień.`,
    bluetoothConsoleName: "Konsola Bluetooth",
    bluetoothConsoleShortDescription:
      "Terminal pozwalający na testowanie modułu Bluetooth w urządzeniu elektronicznym",
    cutScheduleDescription: `\
Wygodny plan zajęć dla studentów i wykładowców Politechniki Częstochowskiej (PCz).

Obecnie wspierane wydziały PCz:
* Wydział Budownictwa
* Wydział Elektryczny
* Wydział Inżynierii Mechanicznej i Informatyki (WIMiI)
* Wydział Inżynierii Produkcji i Technologii Materiałów (WiP)
* Wydział Zarządzania

Cechy:
* Najnowszy plan zajęć grupy laboratoryjnej dostępny bez ciągłego połączenia z internetem - oszczędzaj dane mobilne i bądź przygotowany na awarię strony uczelni
* Zakładki / lista ulubionych grup studenckich, która jeszcze bardziej skraca czas potrzebny na znalezienie odpowiedniego planu zajęć
* Wyszukiwarka grup dziekańskich i laboratoryjnych
* Plany zajęć wykładowców i rozkład położenia sal (jeśli udostępnione przez wydział PCz)`,
    cutScheduleName: "Plan zajęć PCz",
    cutScheduleShortDescription:
      "Wygodny plan zajęć dla studentów i wykładowców Politechniki Częstochowskiej PCz",
    fusiaDescription: `\
Hej! Jestem Fusia, relaksująca gra logiczna wykorzystująca kwadratową planszę i kolorowe kule, którymi możesz ruszać w 4 kierunkach. Kolorowe kule mogą się ze sobą łączyć lub pochłaniać jedna drugą, w zależności od ich barwy i trybu rozgrywki. Jako Fusia posiadam elementy RPG - rozwój postaci gracza. Po każdej rozegranej rundzie otrzymujesz ode mnie punkty doświadczenia oraz gwiazdki. Awansując na wyższe poziomy doświadczenia odblokowujesz nowe tryby gry oraz udogodnienia w trakcie gry. Z uwagi na mój relaksujący, spokojny i kojący charakter, odtwarzam też przyjemną muzykę w tle. Mam świetną pamięć i jestem dobra z matematyki, więc zawsze mogę Ci pokazać Twoje statystyki, żeby zachwycić znajomych. Przekonałam Cię do siebie? Zaprzyjaźnisz się z Fusią?

🥼🔎 Cały czas się rozwijam, żeby jak najlepiej umilić czas Tobie i innym graczom. A Ty możesz mi w tym pomóc. Jeśli będziesz mieć pomysł, co mogłabym w sobie poprawić, albo co dodać, to napisz do mnie na contribute@fusia.kwezal.com 😊

CECHY
🎨 Kolorowa, wielobarwna, pryzmatyczna, piękna
😌 Relaksująca, spokojna, odprężająca, kojąca
🎵 Spokojna muzyka tła
⚔️ Rozwój postaci gracza
📊 Statystyki rozegranych rund i zdobytych gwiazdek
🌞🌝 Jasny i ciemny motyw - możesz grać za dnia i w nocy

JĘZYKI
🇵🇱 Polski
🇺🇸🇬🇧 Angielski

2 TRYBY ROZGRYWKI
🏔️ Paint It, White - Twoim zadaniem jest wypełnienie planszy białymi kulami
🌌 Ciemna materia - Zdobywasz punkty za pochłanianie kolorowych kul

8 KOLORÓW
⚪ Biały
⚫ Czarny
🔴 Czerwony
🟢 Zielony
🔵 Niebieski
🟡 Żółty
🟣 Różowy
🌊 Turkusowy`,
    fusiaShortDescription:
      "Planszowa gra logiczna o łączeniu kolorowych kuli w wielu trybach rozgrywki",
    myPressureDescription: `\
Podręczny dziennik tętna i ciśnienia krwi, w którym możesz zapisywać wyniki pomiarów wraz z komentarzem i regularnie śledzić stan swojego zdrowia. Aplikacja potrafi ocenić każdy z wpisów według obowiązujących norm lekarskich. O prawidłowości klasyfikacji pomiarów zapewnia konsultant medyczny, jednak aplikacja nie może zastąpić wizyty u lekarza. Zalecamy udać się do specjalisty, kiedy tylko zauważysz powtarzające się wysokie lub niskie wyniki.

Moje Ciśnienie pozwala na przeglądanie wykresów statystycznych, rysowanych na podstawie pomiarów z dowolnego, podanego zakresu czasowego. Wykres liniowy w przejrzysty sposób może przedstawiać wzrost lub spadek ciśnienia i pulsu, a wykres kołowy obrazuje stosunek prawidłowych wyników, do tych, odstających od normy.

Eksport dziennika do pliku PDF daje możliwość szybkiego i prostego wysłania, lub wydrukowania i pokazania lekarzowi.

UPRAWNIENIA:
* Dostęp do karty pamięci - jest wymagany, aby aplikacja mogła zapisać plik PDF w odpowiednim miejscu na nośniku danych.`,
    myPressureName: "Moje Ciśnienie",
    myPressureShortDescription:
      "Dziennik tętna i ciśnienia tętniczego krwi pozwalający monitorować stan zdrowia",
    synonymsDescription: ``,
    synonymsShortDescription: "Słownik synonimów dla języka polskiego",
    quizAllDescription: `\
Sprawdź swoją wiedzę rozwiązując różnorodne quizy samemu lub z innymi! 3 różne tryby gry, możliwość tworzenia własnych quizów, oraz opcje dostosowywania rozgrywki pozwolą Ci na dokładnie taką zabawę, na jaką masz ochotę!

Aplikacja zawiera 64 osiągnięcia. Zdobądź je wszystkie!

TRYBY GRY
* Pojedynczy gracz - odpowiadaj na pytania samemu
* Gorący telefon - graj nawet z 10 przyjaciółmi jednocześnie na jednym urządzeniu
* Bluetooth - zmierz się z innym graczem przez Bluetooth

PACZKI PYTAŃ
* Stolice świata
* Symbole chemiczne
* Trudne słowa
* Biblia.

Proszę, miej na uwadze, że jest to wersja beta, co oznacza, że więcej funkcjonalności, trybów gry oraz zawartości zostanie dodanych wkrótce.

POZWOLENIA
- Dostęp do karty SD - potrzebny, aby móc pobrać i użyć paczek pytań innych graczy podczas rozgrywki
- Dostęp do Internetu - wykorzystywany przez Google Play do logowania i przeglądania osiągnięć gracza
- Dostęp do Bluetooth - wymagany, aby zagrać ze znajomymi za pośrednictwem Bluetooth`,
    quizAllShortDescription:
      "Sprawdź swoją wiedzę! Graj ze znajomymi! Stwórz własny quiz!",
  },
});

interface Props {}

interface State {}

export default class AppsView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  shouldComponentUpdate(newProps: Props, newState: State) {
    return hasLanguageChanged(localStr);
  }

  render() {
    setLanguage(localStr);

    const products = AppsView.getProducts();
    return <ProductCards products={products} />;
  }

  private static getProducts(): ProductInfo[] {
    return [
      {
        icon: "synonimy-2021.png",
        name: "Kwezal Synonimy",
        shortDescription: localStr.synonymsShortDescription,
        description: localStr.synonymsDescription,
        releaseYear: 2021,
        platforms: [WebPlatform("https://synonimy.kwezal.com")],
        techStack: [
          TechTypeScript,
          TechReact,
          TechMaterialUi,
          TechSass,
          TechPython,
        ],
      },
      {
        icon: "fusia-logo-2020.png",
        name: "Fusia",
        shortDescription: localStr.fusiaShortDescription,
        description: localStr.fusiaDescription,
        releaseYear: 2020,
        platforms: [
          AndroidPlatform(
            "https://play.google.com/store/apps/details?id=com.kwezal.fusia"
          ),
          WebPlatform("https://fusia.kwezal.com"),
        ],
        techStack: [TechTypeScript, TechReact, TechMaterialUi, TechSass],
      },
      {
        icon: "cut-schedule-logo-2019.png",
        name: localStr.cutScheduleName,
        shortDescription: localStr.cutScheduleShortDescription,
        description: localStr.cutScheduleDescription,
        releaseYear: 2019,
        techStack: [TechKotlin],
      },
      {
        icon: "my-pressure-logo-2019.png",
        name: localStr.myPressureName,
        shortDescription: localStr.myPressureShortDescription,
        description: localStr.myPressureDescription,
        releaseYear: 2019,
        platforms: [
          AndroidPlatform(
            "https://play.google.com/store/apps/details?id=pl.pcz.wimii.mind.mypressure"
          ),
        ],
        techStack: [TechKotlin, TechJava],
      },
      {
        icon: "bluetooth-console-logo-2019-2.png",
        name: localStr.bluetoothConsoleName,
        shortDescription: localStr.bluetoothConsoleShortDescription,
        description: localStr.bluetoothConsoleDescription,
        releaseYear: 2019,
        platforms: [
          AndroidPlatform(
            "https://play.google.com/store/apps/details?id=pl.kwezal.bluetoothconsole"
          ),
        ],
        techStack: [TechKotlin],
      },
      {
        icon: "quizall-logo-2019.png",
        name: "QuizAll",
        shortDescription: localStr.quizAllShortDescription,
        description: localStr.quizAllDescription,
        releaseYear: 2018,
        platforms: [
          AndroidPlatform(
            "https://play.google.com/store/apps/details?id=pl.kwezal.quiz"
          ),
        ],
        techStack: [TechKotlin, TechFirebase],
      },
    ];
  }
}
