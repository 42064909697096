import React, { Component, HTMLAttributes } from "react";

interface Props extends HTMLAttributes<HTMLElement> {
  visible: boolean;
}

interface State {}

export default class TabPanel extends Component<Props, State> {
  private loaded = false;

  render() {
    return (this.props.visible || this.loaded) && this.load();
  }

  private load() {
    this.loaded = true;
    return (
      <div role="tabpanel" hidden={!this.props.visible}>
        {this.props.children}
      </div>
    );
  }
}
