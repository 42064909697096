import {
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import FacebookIcon from "@material-ui/icons/Facebook";
import GitHubIcon from "@material-ui/icons/GitHub";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import React, { Component } from "react";
import LocalizedStrings from "react-localization";
import {
  hasLanguageChanged,
  setLanguage,
} from "../../component/ComponentUtils";
import GoogleTagManager from "../../component/GoogleTagManager";
import { ScreenSize } from "../../component/MainContainer";
import Section, { SectionInfo } from "../../component/Section";
import MediumIcon from "../../icon/MediumIcon";
import colors from "../../style/Colors.scss";
import { BLOG_URL } from "../home/blog/BlogView";
import "./ContactView.scss";

const SECTIONS_COLOR = "primary";

const localStr = new LocalizedStrings({
  en: {
    contactSectionText:
      "You can drop us an email to one of the addresses below depending on the subject you want to discuss.",
    contactSectionTitle: "Contact us",
    emailDescriptionContribute:
      "Want to become a friend of Kwezal and support our mission? In this case it's the mailbox for you. 😊",
    emailDescriptionGeneral: "Kwezal's general purpose mailbox.",
    emailDescriptionMastermind: `Contact our Mastermind directly. This email address is also the response to all requests saying "I'd like to speak to the manager."`,
    socialMediaSectionText: `Kwezal is present on various social media and other websites where we share the content we find important. It's true we don't publish regularly, but that's because we've adopted the principle of quality over quantity. Check it out, maybe some of this content will be interesting or helpful for you. 😉`,
    socialMediaSectionTitle: "Get to know us better",
  },
  pl: {
    contactSectionText:
      "Możesz wysłać nam wiadomość e-mail na jeden z poniższych adresów w zależności od tematu, który chcesz poruszyć.",
    contactSectionTitle: "Skontaktuj się z nami",
    emailDescriptionContribute:
      "Chcesz zostać przyjacielem Kwezala i wesprzeć naszą misję? W takim razie to skrzynka mailowa dla Ciebie. 😊",
    emailDescriptionGeneral:
      "Kwezalowa skrzynka mailowa ogólnego przeznaczenia.",
    emailDescriptionMastermind: `Skontaktuj się bezpośrednio z naszym Mastermindem. Ten adres mailowy jest także odpowiedzią na wszystkie prośby o treści: "Chcę rozmawiać z kierownikiem".`,
    socialMediaSectionText: `Kwezal jest obecny na różnych portalach społecznościowych oraz innych stronach, gdzie dzielimy się ważnymi dla nas treściami. Co prawda nie publikujemy regularnie, ale to dlatego, że przyjęliśmy zasadę "jakość ponad ilość". Sprawdź, być może któreś z tych treści okażą się ciekawe lub pomocne. 😉`,
    socialMediaSectionTitle: "Poznaj nas bliżej",
  },
});

type ContactItemInfo = { address: string; description: string };

type SocialMediaItemInfo = { icon: any; title: string; url: string };

interface Props {
  screenSize: ScreenSize;
  darkTheme: boolean;
}

interface State {}

export default class ContactView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  shouldComponentUpdate(newProps: Props, newState: State) {
    return (
      newProps.darkTheme !== this.props.darkTheme ||
      newProps.screenSize !== this.props.screenSize ||
      hasLanguageChanged(localStr)
    );
  }

  render() {
    setLanguage(localStr);

    const sections: SectionInfo[] = [
      {
        headerInfo: {
          iconCreator: () => <EmailIcon color={SECTIONS_COLOR} />,
          title: localStr.contactSectionTitle,
        },
        contentView: this.createContactSection(),
      },
      {
        headerInfo: {
          iconCreator: () => <EmojiPeopleIcon color={SECTIONS_COLOR} />,
          title: localStr.socialMediaSectionTitle,
        },
        contentView: this.createSocialMediaSection(),
      },
    ];

    return (
      <>
        <GoogleTagManager />
        <Paper elevation={3}>
          {sections.map((section, i) => (
            <Section key={i} section={section} />
          ))}
        </Paper>
      </>
    );
  }

  private createContactSection() {
    const emails: ContactItemInfo[] = [
      {
        address: "contact@kwezal.com",
        description: localStr.emailDescriptionGeneral,
      },
      {
        address: "contribute@kwezal.com",
        description: localStr.emailDescriptionContribute,
      },
      {
        address: "mastermind@kwezal.com",
        description: localStr.emailDescriptionMastermind,
      },
    ];

    return (
      <>
        <Typography className="justify">
          {localStr.contactSectionText}
        </Typography>
        <List dense>
          {emails.map((email, i) => (
            <ListItem
              key={i}
              component={Link}
              href={`mailto:${email.address}`}
              color="inherit"
              underline="none"
              button
            >
              <ListItemText
                primary={email.address}
                primaryTypographyProps={{ className: "bold", color: "primary" }}
                secondary={email.description}
                secondaryTypographyProps={{ color: "textPrimary" }}
              />
            </ListItem>
          ))}
        </List>
      </>
    );
  }

  private createSocialMediaSection() {
    const links: SocialMediaItemInfo[] = [
      {
        icon: (
          <FacebookIcon
            htmlColor={this.props.darkTheme ? undefined : colors.facebookColor}
          />
        ),
        title: "Facebook",
        url: "https://www.facebook.com/KwezalSoftware",
      },
      {
        icon: (
          <TwitterIcon
            htmlColor={this.props.darkTheme ? undefined : colors.twitterColor}
          />
        ),
        title: "Twitter",
        url: "https://twitter.com/kwezalsoftware",
      },
      {
        icon: (
          <InstagramIcon
            htmlColor={this.props.darkTheme ? undefined : colors.instagramColor}
          />
        ),
        title: "Instagram",
        url: "https://www.instagram.com/kwezalsoftware",
      },
      {
        icon: (
          <LinkedInIcon
            htmlColor={this.props.darkTheme ? undefined : colors.linkedInColor}
          />
        ),
        title: "LinkedIn",
        url: "https://www.linkedin.com/company/kwezal",
      },
      {
        icon: (
          <SvgIcon
            htmlColor={this.props.darkTheme ? undefined : colors.mediumColor}
          >
            {MediumIcon}
          </SvgIcon>
        ),
        title: "Medium",
        url: BLOG_URL,
      },
      {
        icon: (
          <GitHubIcon
            htmlColor={this.props.darkTheme ? undefined : colors.githubColor}
          />
        ),
        title: "GitHub",
        url: "https://github.com/kwezal",
      },
    ];

    return (
      <>
        <Typography className="justify">
          {localStr.socialMediaSectionText}
        </Typography>
        <List>
          {this.createSocialMediaItems(
            links,
            this.props.screenSize > ScreenSize.EXTRA_SMALL
          )}
        </List>
      </>
    );
  }

  private createSocialMediaItems(
    links: SocialMediaItemInfo[],
    twoCols: boolean
  ) {
    if (!twoCols) {
      return links.map((link, i) => this.createSocialMediaItem(link, i));
    }

    const rows: any[] = [];
    const halfLength = Math.ceil(links.length / 2);
    for (let i = 0; i < halfLength; ++i) {
      const nextItem = links[i + halfLength];
      const row = (
        <Grid key={i} container>
          <Grid item xs>
            {this.createSocialMediaItem(links[i])}
          </Grid>
          {nextItem === undefined ? null : (
            <Grid item xs>
              {this.createSocialMediaItem(nextItem)}
            </Grid>
          )}
        </Grid>
      );

      rows.push(row);
    }
    return rows;
  }

  private createSocialMediaItem(link: SocialMediaItemInfo, index?: number) {
    return (
      <ListItem
        key={index}
        component={Link}
        href={link.url}
        target="_blank"
        color="inherit"
        underline="none"
        button
      >
        <ListItemIcon>{link.icon}</ListItemIcon>
        <ListItemText primary={link.title} />
      </ListItem>
    );
  }
}
