import {
  Avatar,
  Box,
  ButtonBase,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import LanguageIcon from "@material-ui/icons/Language";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import React, { Component } from "react";
import LocalizedStrings from "react-localization";
import {
  hasLanguageChanged,
  setLanguage,
} from "../../component/ComponentUtils";
import ConstantComponent from "../../component/ConstantComponent";
import GoogleTagManager from "../../component/GoogleTagManager";
import Section, { SectionInfo, SECTIONS_COLOR } from "../../component/Section";
import "./FriendsView.scss";

const localStr = new LocalizedStrings({
  en: {
    friendsSectionTitle: "Contributors & friends",
    emailDescriptionFusia:
      "Email address dedicated to Fusia related help offers.",
    emailDescriptionGeneral:
      "General mailbox for Kwezal friends and contributors.",
    makeFriendsDescription:
      "Do you want <strong>your avatar to be on the list</strong> above? Do you empathize with our <strong>mission</strong>, <strong>vision</strong> and <strong>philosophy</strong>? Or maybe you just have something you want to <strong>share</strong> with us to <strong>support</strong> our work? Anyway, we kindly invite you to write an email to one of the addresses below and become a friend of Kwezal.",
    makeFriendsSection: "Want to make friends with Kwezal?",
  },
  pl: {
    friendsSectionTitle: "Współpracownicy i przyjaciele",
    emailDescriptionFusia:
      "Adres e-mail dedykowany ofertom pomocy związanych z grą Fusia.",
    emailDescriptionGeneral:
      "Ogólna skrzynka mailowa dla przyjaciół i współpracowników Kwezala.",
    makeFriendsDescription:
      "Czy chcesz, aby <strong>Twój awatar znalazł się na liście</strong> powyżej? Identyfikujesz się się z naszą <strong>misją</strong>, <strong>wizją</strong> i <strong>filozofią</strong>? A może zwyczajnie masz coś, czym chcesz się z nami <strong>podzielić</strong>, by <strong>wesprzeć</strong> naszą pracę? Tak czy inaczej serdecznie zapraszamy do kontaktu na jeden z poniższych adresów e-mail i zostania przyjacielem Kwezala.",
    makeFriendsSection: "Chcesz się zaprzyjaźnić z Kwezalem?",
  },
});

type ContactItemInfo = { address: string; description: string };

interface Props {}

interface State {
  popperOpen: boolean;
  popperAnchor?: HTMLElement;
  selectedFriend?: FriendInfo;
}

export default class FriendsView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.openFriendPopper = this.openFriendPopper.bind(this);
    this.closePopper = this.closePopper.bind(this);

    this.state = {
      popperOpen: false,
    };
  }

  componentDidMount() {}

  shouldComponentUpdate(newProps: Props, newState: State) {
    return (
      newState.popperOpen !== this.state.popperOpen ||
      newState.popperAnchor !== this.state.popperAnchor ||
      hasLanguageChanged(localStr)
    );
  }

  render() {
    setLanguage(localStr);

    const sections: SectionInfo[] = [
      {
        headerInfo: {
          iconCreator: () => <FavoriteIcon color={SECTIONS_COLOR} />,
          title: localStr.friendsSectionTitle,
        },
        contentView: this.createContributorsSection(),
      },
    ];

    return (
      <>
        <GoogleTagManager />
        <Paper elevation={3}>
          {sections.map((section, i) => (
            <Section key={i} section={section} />
          ))}
        </Paper>
      </>
    );
  }

  private createContributorsSection() {
    const emails: ContactItemInfo[] = [
      {
        address: "contribute@kwezal.com",
        description: localStr.emailDescriptionGeneral,
      },
      {
        address: "contribute@fusia.kwezal.com",
        description: localStr.emailDescriptionFusia,
      },
    ];

    return (
      <>
        <FriendAvatars onClick={this.openFriendPopper} />
        <Box my={2}>
          <Typography
            className="bold"
            variant="h6"
            component="h2"
            color="secondary"
          >
            {localStr.makeFriendsSection}
          </Typography>
          <Typography
            className="justify"
            dangerouslySetInnerHTML={{
              __html: localStr.makeFriendsDescription,
            }}
          ></Typography>
          <List dense>
            {emails.map((email, i) => (
              <ListItem
                key={i}
                component={Link}
                href={`mailto:${email.address}`}
                color="inherit"
                underline="none"
                button
              >
                <ListItemText
                  primary={email.address}
                  primaryTypographyProps={{
                    className: "bold",
                    color: "primary",
                  }}
                  secondary={email.description}
                  secondaryTypographyProps={{ color: "textPrimary" }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <Popper
          open={this.state.popperOpen}
          anchorEl={this.state.popperAnchor}
          placement="bottom"
          disablePortal
          modifiers={
            {
              /* arrow: {
              enabled: true,
              element: arrowRef,
            }, */
            }
          }
        >
          <Paper elevation={3}>
            <Box p={1}>
              <Typography variant="h6" color="primary" className="bold">
                {this.state.selectedFriend?.name}
              </Typography>
              {this.state.selectedFriend?.links && (
                <List>
                  {this.state.selectedFriend.links.map((link, i) => (
                    <ListItem
                      key={i}
                      color="inherit"
                      component={Link}
                      href={link.url}
                      target="_blank"
                      underline="none"
                      button
                    >
                      <ListItemIcon>{link.icon}</ListItemIcon>
                      <ListItemText primary={link.name} />
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
          </Paper>
        </Popper>
      </>
    );
  }

  private openFriendPopper(avatar: HTMLElement, friend: FriendInfo) {
    if (this.state.popperOpen && avatar === this.state.popperAnchor) {
      this.setState({ popperOpen: false });
    } else {
      this.setState({
        popperOpen: true,
        popperAnchor: avatar,
        selectedFriend: friend,
      });
    }
  }

  private closePopper() {
    this.setState({ popperOpen: false });
  }
}

type FriendLink = { icon: any; url: string; name: string };

type FriendInfo = {
  name: string;
  avatar?: string;
  links?: FriendLink[];
};

interface FriendAvatarsProps {
  onClick: (avatar: HTMLElement, friend: FriendInfo) => void;
}

class FriendAvatars extends ConstantComponent<FriendAvatarsProps> {
  render() {
    setLanguage(localStr);

    const friends: FriendInfo[] = [
      {
        name: "Anastasia Sumanova",
        avatar: "../asumva-logo-2020.png",
        links: [
          {
            icon: <LanguageIcon />,
            url: "https://asumva.com",
            name: "asumva.com",
          },
        ],
      },
      { name: "Art Director" },
      {
        name: "Krzysztof Sołtysiak",
        avatar: "krzysztof-soltysiak.png",
        links: [
          {
            icon: <LanguageIcon />,
            url: "https://krzysztofsoltysiak.pl",
            name: "krzysztofsoltysiak.pl",
          },
        ],
      },
    ];
    return (
      <AvatarGroup spacing={1}>
        {friends.map((friend, i) => (
          <Avatar
            component={ButtonBase}
            key={i}
            className="friend-avatar"
            alt={friend.name}
            src={friend.avatar && `/graphics/friends/${friend.avatar}`}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
              this.props.onClick(e.currentTarget as HTMLElement, friend)
            }
          >
            {friend.name
              .split(" ")
              .map((part) => part.charAt(0))
              .join("")}
          </Avatar>
        ))}
      </AvatarGroup>
    );
  }
}
