import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@material-ui/core";
import React, { Component } from "react";
import "./BackToTop.scss";
import { Link as RouterLink } from "react-router-dom";
import LinkIcon from "@material-ui/icons/Link";
import { SECTIONS_COLOR } from "./Section";
import { createNewRoute } from "./ComponentUtils";

export type SectionHeaderInfo = {
  iconCreator: (colored: boolean) => any;
  title: string;
  route?: string;
};

interface Props {
  header: SectionHeaderInfo;
}

interface State {}

export default class SectionHeader extends Component<Props, State> {
  shouldComponentUpdate(newProps: Props) {
    return newProps.header.title !== this.props.header.title;
  }

  render() {
    return (
      <AppBar
        key="header"
        position="static"
        color="default"
        className="section-header-bar"
      >
        <Toolbar>
          <Box mr={2}>{this.props.header.iconCreator(true)}</Box>
          <Typography
            className="bold"
            variant="button"
            component="h1"
            color={SECTIONS_COLOR}
          >
            {this.props.header.title}
          </Typography>
          {this.props.header.route && <div className="grow" />}
          {this.props.header.route && (
            <IconButton
              className="link"
              component={RouterLink}
              to={createNewRoute(this.props.header.route)}
              replace
            >
              <LinkIcon color="secondary" />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
    );
  }
}
