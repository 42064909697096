import { Paper } from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import React, { Component } from "react";
import LocalizedStrings from "react-localization";
import {
  getRoute,
  getSectionName,
  hasLanguageChanged,
  setLanguage,
} from "../../component/ComponentUtils";
import GoogleTagManager from "../../component/GoogleTagManager";
import { ScreenSize } from "../../component/MainContainer";
import Section, { SectionInfo, SECTIONS_COLOR } from "../../component/Section";
import TabSections from "../../component/TabSections";
import {ROUTE_BLOG, ROUTE_NEST, ROUTE_TEAM} from "../../Routes";
import BlogView from "./blog/BlogView";
import "./HomeView.scss";
import TeamView from "./team/TeamView";

const localStr = new LocalizedStrings({
  en: {
    aboutViewTitle: "About Kwezal",
    blogViewTitle: "Blog",
    brandViewTitle: "Brand",
    philosophyViewTitle: "Philosophy",
    teamViewTitle: "Team",
  },
  pl: {
    aboutViewTitle: "O Kwezalu",
    blogViewTitle: "Blog",
    brandViewTitle: "Marka",
    philosophyViewTitle: "Filozofia",
    teamViewTitle: "Zespół",
  },
});

interface Props {
  darkTheme: boolean;
  screenSize: ScreenSize;
}

interface State {}

export default class HomeView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  shouldComponentUpdate(newProps: Props, newState: State) {
    return (
      newProps.darkTheme !== this.props.darkTheme ||
      newProps.screenSize !== this.props.screenSize ||
      hasLanguageChanged(localStr)
    );
  }

  render() {
    setLanguage(localStr);

    return (
      <>
        <GoogleTagManager />
        <Paper elevation={3}>
          {this.props.screenSize >= ScreenSize.MEDIUM
            ? this.createContinousSections()
            : this.createTabsSections()}
        </Paper>
      </>
    );
  }

  private createContinousSections() {
    const sections = this.getSections();
    return sections.map((section, i) => <Section key={i} section={section} />);
  }

  private createTabsSections() {
    const sections = this.getSections();
    return (
      <TabSections
        sections={sections}
        initialTabIndex={HomeView.getTabIndexByRoute(getRoute())}
      />
    );
  }

  private getSections(): SectionInfo[] {
    return [
      /*{
        headerInfo: {
          iconCreator: (colored: boolean) => (
            <BusinessIcon color={colored ? SECTIONS_COLOR : undefined} />
          ),
          title: localStr.aboutViewTitle,
          route: ROUTE_ABOUT,
        },
        contentView: <AboutView />,
      },*/
      {
        headerInfo: {
          iconCreator: (colored: boolean) => (
            <GroupIcon color={colored ? SECTIONS_COLOR : undefined} />
          ),
          title: localStr.teamViewTitle,
          route: `${ROUTE_NEST}/${ROUTE_TEAM}`,
        },
        contentView: <TeamView darkTheme={this.props.darkTheme} />,
      },
      {
        headerInfo: {
          iconCreator: (colored: boolean) => (
            <LocalLibraryIcon color={colored ? SECTIONS_COLOR : undefined} />
          ),
          title: localStr.blogViewTitle,
          route: `${ROUTE_NEST}/${ROUTE_BLOG}`,
        },
        contentView: <BlogView darkTheme={this.props.darkTheme} />,
      },
      /* {
        iconCreator: (colored: boolean) => (
          <AccountBalanceIcon color={colored ? SECTIONS_COLOR : undefined} />
        ),
        title: localStr.philosophyViewTitle,
        route: ROUTE_PHILOSOPHY,
        contentView: <PhilosophyView />,
      }, */
    ];
  }

  private static getTabIndexByRoute(route: string) {
    switch (getSectionName(1, route)) {
      case ROUTE_BLOG:
        return 1;
      /* case ROUTE_PHILOSOPHY:
          return 3; */
      default:
        return 0;
    }
  }
}
