import { AppBar, Box, Tab, Tabs, Typography } from "@material-ui/core";
import React from "react";
import { Component } from "react";
import { Link as RouterLink } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { createNewRoute } from "./ComponentUtils";
import { SectionInfo, SECTIONS_COLOR } from "./Section";
import TabPanel from "./TabPanel";

interface Props {
  sections: SectionInfo[];
  initialTabIndex: number;
}

interface State {
  tabIndex: number;
}

export default class TabSections extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.setTabIndex = this.setTabIndex.bind(this);
    this.setTabIndexOnChange = this.setTabIndexOnChange.bind(this);

    this.state = { tabIndex: props.initialTabIndex };
  }

  shouldComponentUpdate(newProps: Props, newState: State) {
    return (
      newState.tabIndex !== this.state.tabIndex ||
      newProps.sections !== this.props.sections
    );
  }

  render() {
    return (
      <>
        <AppBar position="static" color="default">
          <nav>
            <Tabs
              className="section-tabs"
              value={this.state.tabIndex}
              onChange={this.setTabIndexOnChange}
              indicatorColor={SECTIONS_COLOR}
              textColor={SECTIONS_COLOR}
              variant="scrollable"
            >
              {this.props.sections.map((section, i) => (
                <Tab
                  key={i}
                  component={RouterLink}
                  to={
                    section.headerInfo.route
                      ? createNewRoute(section.headerInfo.route)
                      : ""
                  }
                  replace
                  label={
                    <Typography className="bold" component="h1">
                      {section.headerInfo.title}
                    </Typography>
                  }
                  icon={section.headerInfo.iconCreator(false)}
                />
              ))}
            </Tabs>
          </nav>
        </AppBar>
        <Box p={2}>
          <SwipeableViews
            axis={"x"}
            index={this.state.tabIndex}
            onChangeIndex={this.setTabIndex}
          >
            {this.props.sections.map((section, i) => (
              <TabPanel key={i} visible={this.state.tabIndex === i}>
                {section.contentView}
              </TabPanel>
            ))}
          </SwipeableViews>
        </Box>
      </>
    );
  }

  private setTabIndexOnChange(e: any, value: number) {
    this.setTabIndex(value);
  }

  private setTabIndex(value: number) {
    this.setState({ tabIndex: value });
  }
}
