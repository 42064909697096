import React from "react";
import ConstantComponent from "./ConstantComponent";

const TAG_ID = "UA-104391886-2";

export default class GoogleTagManager extends ConstantComponent {
  render() {
    return (
      <>
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${TAG_ID}`}
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `\
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${TAG_ID}');`,
          }}
        />
      </>
    );
  }

  a() {}
}
