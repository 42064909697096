import LocalizedStrings from "react-localization";

const str = new LocalizedStrings({
  en: {
    contactViewTitle: "Contact",
    friendsViewTitle: "Friends",
    homeViewTitle: "Kwezal Nest",
    productsViewTitle: "Products",
  },
  pl: {
    contactViewTitle: "Kontakt",
    friendsViewTitle: "Przyjaciele",
    homeViewTitle: "Gniazdo Kwezala",
    productsViewTitle: "Produkty",
  },
});

export default str;
