import { Paper } from "@material-ui/core";
import AppsIcon from "@material-ui/icons/Apps";
import PublicIcon from "@material-ui/icons/Public";
import WebIcon from "@material-ui/icons/Web";
import React, { Component } from "react";
import LocalizedStrings from "react-localization";
import {
  getRoute,
  getSectionName,
  hasLanguageChanged,
  setLanguage,
} from "../../component/ComponentUtils";
import GoogleTagManager from "../../component/GoogleTagManager";
import { ScreenSize } from "../../component/MainContainer";
import Section, { SectionInfo, SECTIONS_COLOR } from "../../component/Section";
import TabSections from "../../component/TabSections";
import {
  ROUTE_PRODUCTS,
  ROUTE_PRODUCTS_APPS,
  ROUTE_PRODUCTS_OPEN_SOURCE,
  ROUTE_PRODUCTS_WEBSITES,
} from "../../Routes";
import AppsView from "./apps/AppsView";
import OpenSourceView from "./open-source/OpenSourceView";
import "./ProductsView.scss";
import WebsitesView from "./websites/WebsitesView";

export const localStr = new LocalizedStrings({
  en: {
    appsViewTitle: "Apps & games",
    emailDescriptionFusia: "Talk with Fusia and ask her anything",
    openSourceViewTitle: "Open-source",
    techStack: "Tech stack",
    websitesViewTitle: "Websites",
  },
  pl: {
    appsViewTitle: "Gry i aplikacje",
    emailDescriptionFusia: "Porozmawiaj z Fusią i zadaj jej dowolne pytanie",
    openSourceViewTitle: "Otwarte oprogramowanie",
    techStack: "Stack technologiczny",
    websitesViewTitle: "Strony internetowe",
  },
});

interface Props {
  screenSize: ScreenSize;
}

interface State {}

export default class ProductsView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {}

  shouldComponentUpdate(newProps: Props, newState: State) {
    return (
      newProps.screenSize !== this.props.screenSize ||
      hasLanguageChanged(localStr)
    );
  }

  render() {
    setLanguage(localStr);

    return (
      <>
        <GoogleTagManager />
        <Paper elevation={3}>
          {this.props.screenSize >= ScreenSize.MEDIUM
            ? this.createContinuousSections()
            : this.createTabsSections()}
        </Paper>
      </>
    );
  }

  private createContinuousSections() {
    const sections = this.getSections();
    return sections.map((section, i) => <Section key={i} section={section} />);
  }

  private createTabsSections() {
    const sections = this.getSections();
    return (
      <TabSections
        sections={sections}
        initialTabIndex={ProductsView.getTabIndexByRoute(getRoute())}
      />
    );
  }

  private getSections(): SectionInfo[] {
    return [
      {
        headerInfo: {
          iconCreator: (colored: boolean) => (
            <AppsIcon color={colored ? SECTIONS_COLOR : undefined} />
          ),
          title: localStr.appsViewTitle,
          route: `${ROUTE_PRODUCTS}/${ROUTE_PRODUCTS_APPS}`,
        },
        contentView: <AppsView />,
      },
      {
        headerInfo: {
          iconCreator: (colored: boolean) => (
            <WebIcon color={colored ? SECTIONS_COLOR : undefined} />
          ),
          title: localStr.websitesViewTitle,
          route: `${ROUTE_PRODUCTS}/${ROUTE_PRODUCTS_WEBSITES}`,
        },
        contentView: <WebsitesView />,
      },
      {
        headerInfo: {
          iconCreator: (colored: boolean) => (
            <PublicIcon color={colored ? SECTIONS_COLOR : undefined} />
          ),
          title: localStr.openSourceViewTitle,
          route: `${ROUTE_PRODUCTS}/${ROUTE_PRODUCTS_OPEN_SOURCE}`,
        },
        contentView: <OpenSourceView />,
      },
    ];
  }

  private static getTabIndexByRoute(route: string) {
    switch (getSectionName(1, route)) {
      case ROUTE_PRODUCTS_WEBSITES:
        return 1;
      case ROUTE_PRODUCTS_OPEN_SOURCE:
        return 2;
      default:
        return 0;
    }
  }
}
