import { Component } from "react";

export default abstract class ConstantComponent<
  P = {},
  S = {}
> extends Component<P, S> {
  shouldComponentUpdate() {
    return false;
  }
}
