import { LocalizedStrings } from "react-localization";
import str from "../GlobalStringResources";
import {ROUTE_CONTACT, ROUTE_FRIENDS, ROUTE_NEST, ROUTE_PRODUCTS} from "../Routes";

export function getRouteId(route: string = getRoute()) {
  return isHomeRoute(route) ? "home" : getMainRouteId(route);
}

export function getSectionName(level: number = 1, route: string = getRoute()) {
  const parts = route.substring(1).split("/");
  return isHomeRoute(route) ? parts[level] : parts[level + 1];
}

export function getMainRouteId(route: string = getRoute()) {
  if (isHomeRoute(route)) {
    return "";
  }

  const slashPos = route.indexOf("/", 4);
  let end;
  if (slashPos !== -1) {
    end = slashPos;
  }

  return route.substring(4, end);
}

export function isHomeRoute(route: string = getRoute()) {
  let routeWithoutSlash = route.substring(4);
  const slashPos = routeWithoutSlash.indexOf("/", 1);
  if (slashPos !== -1) {
    routeWithoutSlash = routeWithoutSlash.substring(0, slashPos);
  }

  return (
    [ROUTE_PRODUCTS, ROUTE_FRIENDS, ROUTE_CONTACT, ROUTE_NEST].indexOf(
      routeWithoutSlash
    ) === -1
  );
}

export function getRoute(pathName?: string) {
  return pathName || window.location.pathname;
}

export function createNewRoutes(...routes: string[]) {
  return routes.map((route) => createNewRoute(route));
}

export function createNewRoute(route: string) {
  return `/${getLanguageCode()}/${route}`;
}

export function hasLanguageChanged(strings: LocalizedStrings<any>) {
  return strings.getLanguage() !== str.getLanguage();
}

export function setLanguage(
  strings: LocalizedStrings<any>,
  languageCode: string | undefined = getLanguageCode()
) {
  strings.setLanguage(languageCode);
}

export function getLanguageCode(route: string = getRoute()) {
  return route.length < 3 || ["/", ""].indexOf(route.charAt(3)) === -1
    ? undefined
    : route.substr(1, 2);
}
