import {
  FaPython,
  FaSass,
  GrReactjs,
  SiFirebase,
  SiJava,
  SiKotlin,
  SiMaterialUi,
  SiTypescript,
} from "react-icons/all";
import React from "react";

export type TechInfo = {
  icon: any;
  name: string;
};

export const TechFirebase: TechInfo = {
  icon: <SiFirebase title="Firebase" />,
  name: "Firebase",
};

// export const TechFlutter: TechInfo = {
//   icon: <SiFlutter />,
//   name: "Flutter",
// };

export const TechJava: TechInfo = {
  icon: <SiJava title="Java" />,
  name: "Java",
};

export const TechKotlin: TechInfo = {
  icon: <SiKotlin title="Kotlin" />,
  name: "Kotlin",
};

export const TechMaterialUi: TechInfo = {
  icon: <SiMaterialUi title="Material-UI" />,
  name: "Material-UI",
};

export const TechPython: TechInfo = {
  icon: <FaPython title="Python" />,
  name: "Python",
};

export const TechReact: TechInfo = {
  icon: <GrReactjs title="React" />,
  name: "React",
};

export const TechSass: TechInfo = {
  icon: <FaSass title="Sass" />,
  name: "Sass",
};

export const TechTypeScript: TechInfo = {
  icon: <SiTypescript title="TypeScript" />,
  name: "TypeScript",
};