import LanguageIcon from "@material-ui/icons/Language";
import AndroidIcon from "@material-ui/icons/Android";
import AppleIcon from "@material-ui/icons/Apple";
import GitHubIcon from "@material-ui/icons/GitHub";
import React from "react";

export default class ProductPlatform {
  readonly icon: any;
  readonly url: string;

  constructor(icon: any, url: string) {
    this.icon = icon;
    this.url = url;
  }
}

export function AndroidPlatform(url: string) {
  return new ProductPlatform(<AndroidIcon />, url);
}

export function iOsPlatform(url: string) {
  return new ProductPlatform(<AppleIcon />, url);
}

export function WebPlatform(url: string) {
  return new ProductPlatform(<LanguageIcon />, url);
}

export function GitHubPlatform(url: string) {
  return new ProductPlatform(<GitHubIcon />, url);
}
