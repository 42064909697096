export const ROUTE_NEST = "nest";
export const ROUTE_BLOG = "blog";
export const ROUTE_PHILOSOPHY = "philosophy";

export const ROUTE_TEAM = "team";
export const ROUTE_TEAM_ARTI = "arti";
export const ROUTE_TEAM_MASTERMIND = "mastermind";

export const ROUTE_PRODUCTS = "products";
export const ROUTE_PRODUCTS_APPS = "apps";
export const ROUTE_PRODUCTS_OPEN_SOURCE = "opensource";
export const ROUTE_PRODUCTS_WEBSITES = "websites";

export const ROUTE_FRIENDS = "friends";
export const ROUTE_CONTACT = "contact";
