import LocalizedStrings from "react-localization";
import { sprintf } from "sprintf-js";

const localStr = new LocalizedStrings({
  en: {
    dayOfMonthMonthFormat: "%(month)s %(day)d ",
    dayOfMonthMonthYearFormat: "%(month)s %(day)d, %(year)d",

    month0: "January",
    month1: "February",
    month2: "March",
    month3: "April",
    month4: "May",
    month5: "June",
    month6: "July",
    month7: "August",
    month8: "September",
    month9: "October",
    month10: "November",
    month11: "December",

    monthShort0: "Jan",
    monthShort1: "Feb",
    monthShort2: "Mar",
    monthShort3: "Apr",
    monthShort4: "May",
    monthShort5: "Jun",
    monthShort6: "Jul",
    monthShort7: "Aug",
    monthShort8: "Sep",
    monthShort9: "Oct",
    monthShort10: "Nov",
    monthShort11: "Dec",
  },
  pl: {
    dayOfMonthMonthFormat: "%(day)d %(month)s",
    dayOfMonthMonthYearFormat: "%(day)d %(month)s, %(year)d",

    month0: "stycznia",
    month1: "lutego",
    month2: "marca",
    month3: "kwietnia",
    month4: "maja",
    month5: "czerwca",
    month6: "lipca",
    month7: "sierpnia",
    month8: "września",
    month9: "października",
    month10: "listopada",
    month11: "grudnia",

    monthShort0: "Sty",
    monthShort1: "Lut",
    monthShort2: "Mar",
    monthShort3: "Kwi",
    monthShort4: "Maj",
    monthShort5: "Cze",
    monthShort6: "Lip",
    monthShort7: "Sie",
    monthShort8: "Wrz",
    monthShort9: "Paź",
    monthShort10: "Lis",
    monthShort11: "Gru",
  },
});

class TimeUtils {
  static formatLongDate(
    timestampMillis: number,
    hideYearIfCurrent: boolean = false
  ) {
    const now = new Date();
    const date = new Date(timestampMillis);
    const dayOfMonth = date.getUTCDate();
    const month = date.getUTCMonth();
    const year = date.getUTCFullYear();

    if (hideYearIfCurrent && now.getUTCFullYear() === year) {
      return sprintf(localStr.dayOfMonthMonthFormat, {
        day: dayOfMonth,
        month: localStr.getString(`month${month}`),
      });
    } else {
      return sprintf(localStr.dayOfMonthMonthYearFormat, {
        day: dayOfMonth,
        month: localStr.getString(`month${month}`),
        year: year,
      });
    }
  }
}

export default TimeUtils;
