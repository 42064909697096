import React from "react";

const MediumIcon = (
  <g transform="matrix(1.3333 0 0 -1.3333 0 1534)">
    <g transform="matrix(.1 0 0 .1 18.122 -5.3299)">
      <g transform="matrix(.012936 0 0 .012936 -196.86 11394)">
        <path d="m8834.5 5742.5c0-1636.7-1317.8-2963.5-2943.3-2963.5-1625.6 0-2943.3 1326.8-2943.3 2963.5 0 1636.7 1317.8 2963.5 2943.3 2963.5 1625.5 0 2943.3-1326.8 2943.3-2963.5" />
        <path d="m12063 5742.5c0-1540.7-658.9-2789.6-1471.7-2789.6-812.76 0-1471.6 1249-1471.6 2789.6 0 1540.7 658.89 2789.6 1471.6 2789.6 812.8 0 1471.7-1249 1471.7-2789.6" />
        <path d="m13384 5742.5c0-1380.4-231.7-2499.4-517.6-2499.4-285.8 0-517.6 1119-517.6 2499.4 0 1380.4 231.8 2499.4 517.6 2499.4 285.9 0 517.6-1119 517.6-2499.4" />
      </g>
    </g>
  </g>
);

export default MediumIcon;
