import { createMuiTheme, Theme } from "@material-ui/core";
import colors from "../style/Colors.scss";

export class AppTheme {
  private cache?: Theme;
  private readonly creator: () => Theme;

  readonly id: string;
  readonly isDark: boolean;

  constructor(id: string, isDark: boolean, creator: () => Theme) {
    this.id = id;
    this.isDark = isDark;
    this.creator = creator;
  }

  get(allowCache: boolean = true) {
    if (!allowCache || this.cache === undefined) {
      this.cache = this.creator();
    }
    return this.cache;
  }
}

export class AppThemes {
  static readonly HOME_LIGHT = new AppTheme("nest-light", false, () =>
    createLightHomeTheme()
  );

  static readonly HOME_DARK = new AppTheme("nest-dark", true, () =>
    createDarkHomeTheme()
  );

  static readonly PRODUCTS_LIGHT = new AppTheme("products-light", false, () =>
    createLightProductsTheme()
  );

  static readonly PRODUCTS_DARK = new AppTheme("products-dark", true, () =>
    createDarkProductsTheme()
  );

  static readonly FRIENDS_LIGHT = new AppTheme("friends-light", false, () =>
    createLightFriendsTheme()
  );

  static readonly FRIENDS_DARK = new AppTheme("friends-dark", true, () =>
    createDarkFriendsTheme()
  );

  static readonly CONTACT_LIGHT = new AppTheme("contact-light", false, () =>
    createLightContactTheme()
  );

  static readonly CONTACT_DARK = new AppTheme("contact-dark", true, () =>
    createDarkContactTheme()
  );

  static values = [
    AppThemes.HOME_LIGHT,
    AppThemes.HOME_DARK,
    AppThemes.PRODUCTS_LIGHT,
    AppThemes.PRODUCTS_DARK,
    AppThemes.FRIENDS_LIGHT,
    AppThemes.FRIENDS_DARK,
    AppThemes.CONTACT_LIGHT,
    AppThemes.CONTACT_DARK,
  ];

  static byId(id: string) {
    return (
      AppThemes.values.find((theme) => theme.id === id) || AppThemes.HOME_DARK
    );
  }
}

const createLightHomeTheme = () =>
  createTheme(
    "light",
    colors.primaryColorLightHome,
    colors.secondaryColorLightHome
  );

const createDarkHomeTheme = () =>
  createTheme(
    "dark",
    colors.primaryColorDarkHome,
    colors.secondaryColorDarkHome
  );

const createLightProductsTheme = () =>
  createTheme(
    "light",
    colors.primaryColorProducts,
    colors.secondaryColorProducts
  );

const createDarkProductsTheme = () =>
  createTheme(
    "dark",
    colors.primaryColorProducts,
    colors.secondaryColorProducts
  );

const createLightFriendsTheme = () =>
  createTheme(
    "light",
    colors.primaryColorFriends,
    colors.secondaryColorFriends
  );

const createDarkFriendsTheme = () =>
  createTheme("dark", colors.primaryColorFriends, colors.secondaryColorFriends);

const createLightContactTheme = () =>
  createTheme(
    "light",
    colors.primaryColorContact,
    colors.secondaryColorContact
  );

const createDarkContactTheme = () =>
  createTheme("dark", colors.primaryColorContact, colors.secondaryColorContact);

function createTheme(
  type: "light" | "dark",
  primary: string,
  secondary: string
) {
  return createMuiTheme({
    palette: {
      type: type,
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
      },
    },
  });
}
