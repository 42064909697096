import React, { Component } from "react";
import LocalizedStrings from "react-localization";
import {
  hasLanguageChanged,
  setLanguage,
} from "../../../component/ComponentUtils";
import { ProductInfo } from "../ProductCard";
import ProductCards from "../ProductCards";
import { GitHubPlatform } from "../ProductPlatform";
import { TechKotlin } from "../Technology";

const localStr = new LocalizedStrings({
  en: {
    aeroskopDescription: `\
An Android camera management library using both old camera and new camera2 APIs and providing single, unified, and neat interface.`,
    aeroskopShortDescription:
      "Android camera manager providing unified interface for old and new API",
    kandyDescription: `\
A Kotlin library set dedicated to Android platform which will make programming sweet again! Currently it consists of three modules: List Views, Dialogs and Logs.`,
    kandyShortDescription: "Sweet Android libraries written in Kotlin",
  },
  pl: {
    aeroskopDescription: `\
Biblioteka na Androida do zarządzania kamerą, która używa zarówno starego, jak i nowego API, oraz oferuje jeden, ujednolicony i zgrabny interfejs.`,
    aeroskopShortDescription:
      "Menedżer kamery na Androida udostępniający ujednolicony interfejs do starego i nowego API",
    kandyDescription: `\
Zbiór bibliotek napisanych w Kotlinie dedykowany platformie Android, który sprawi, że programowanie znów stanie się słodkie! Obecnie zbiór składa się z trzech modułów: List Views, Dialogs i Logs.`,
    kandyShortDescription: "Słodkie biblioteki na Androida napisane w Kotlinie",
  },
});

interface Props {}

interface State {}

export default class OpenSourceView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  shouldComponentUpdate(newProps: Props, newState: State) {
    return hasLanguageChanged(localStr);
  }

  render() {
    setLanguage(localStr);

    const products = OpenSourceView.getProducts();
    return <ProductCards products={products} />;
  }

  private static getProducts(): ProductInfo[] {
    return [
      {
        name: "Aeroskop",
        shortDescription: localStr.aeroskopShortDescription,
        description: localStr.aeroskopDescription,
        releaseYear: 2020,
        platforms: [GitHubPlatform("https://github.com/kwezal/aeroskop")],
        techStack: [TechKotlin],
      },
      {
        name: "Kandy",
        shortDescription: localStr.kandyShortDescription,
        description: localStr.kandyDescription,
        releaseYear: 2019,
        platforms: [GitHubPlatform("https://github.com/kwezal/kandy")],
        techStack: [TechKotlin],
      },
    ];
  }
}
