import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  ThemeProvider,
  Toolbar,
} from "@material-ui/core";
import Brightness3Icon from "@material-ui/icons/Brightness3";
import MenuIcon from "@material-ui/icons/Menu";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import React, { Component } from "react";
import { Route, RouteComponentProps } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import str from "../GlobalStringResources";
import Language from "../language/Language";
import {
  ROUTE_BLOG,
  ROUTE_CONTACT,
  ROUTE_FRIENDS,
  ROUTE_NEST,
  ROUTE_PHILOSOPHY,
  ROUTE_PRODUCTS,
  ROUTE_TEAM,
} from "../Routes";
import breakpoints from "../style/Breakpoints.scss";
import "../style/Common.scss";
import { AppThemes } from "../theme/Theme";
import ContactView from "../view/contact/ContactView";
import FriendsView from "../view/friends/FriendsView";
import HomeView from "../view/home/HomeView";
import ProductsView from "../view/products/ProductsView";
import BackToTop from "./BackToTop";
import {
  createNewRoute,
  createNewRoutes,
  getLanguageCode,
  getMainRouteId,
  getRoute,
  getRouteId,
  setLanguage,
} from "./ComponentUtils";
import LanguageMenuItems from "./LanguageMenuItems";
import "./MainContainer.scss";
import ParticlesBackground from "./ParticlesBackground";
import RouterOutlet from "./RouterOutlet";

const RetroMode = React.lazy(() => import("./RetroMode"));

const RESIZE_INTERVAL = 50;

const SCROLL_INTERVAL = 300;
const SCROLL_THRESHOLD = 256;

const SNACKBAR_DURATION = 1000;

const SUN_COLOR = "#ffd54f";
const MOON_COLOR = "#4527a0";

const KONAMI_CODE = [
  "ArrowUp",
  "ArrowUp",
  "ArrowDown",
  "ArrowDown",
  "ArrowLeft",
  "ArrowRight",
  "ArrowLeft",
  "ArrowRight",
  "b",
  "a",
];

export enum ScreenSize {
  EXTRA_SMALL,
  SMALL,
  MEDIUM,
  LARGE,
  EXTRA_LARGE,
}

type NavigationItemInfo = { text: string; route: string };

interface Props extends RouteComponentProps {
  darkTheme: boolean;
  onThemeChange: (dark: boolean) => void;
  onRouteChange: (route: string) => void;
}

interface State {
  screenSize: ScreenSize;
  navigationMenuAnchor?: HTMLElement;
  languageMenuAnchor?: HTMLElement;
  backToTopVisible: boolean;
  snackbarOpen: boolean;
  snackbarText: string;

  retroMode: boolean;
}

export default class MainContainer extends Component<Props, State> {
  private readonly scrollbaleContentRef: React.RefObject<HTMLDivElement> =
    React.createRef();

  private resizeTimeout?: number;
  private readonly onResize = () => {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = window.setTimeout(() => {
      this.updateScreenSize(window.innerWidth);
    }, RESIZE_INTERVAL);
  };

  private contentScrollTimeout?: number;
  private lastScroll = 0;
  private readonly onContentScroll = (e: any) => {
    clearTimeout(this.contentScrollTimeout);
    this.contentScrollTimeout = window.setTimeout(() => {
      this.updateBackToTopButton(e.target.scrollTop);
    }, SCROLL_INTERVAL);
  };

  private readonly typedCode: string[] = [];
  private readonly onKeyDown = (e: KeyboardEvent) => {
    if (this.state.retroMode) {
      return;
    }

    const key = e.key;
    const typedCodeLength = this.typedCode.length;
    if (key.toLowerCase() === KONAMI_CODE[typedCodeLength].toLowerCase()) {
      this.typedCode.push(key);

      if (typedCodeLength + 1 === KONAMI_CODE.length) {
        this.setState({ retroMode: true });
      }
    } else if (typedCodeLength > 0) {
      this.typedCode.length = 0;
    }
  };

  constructor(props: Props) {
    super(props);

    this.onLoad = this.onLoad.bind(this);
    this.switchTheme = this.switchTheme.bind(this);
    this.openNavigationMenu = this.openNavigationMenu.bind(this);
    this.onNavigationMenuClose = this.onNavigationMenuClose.bind(this);
    this.openLanguageMenu = this.openLanguageMenu.bind(this);
    this.onLanguageMenuClose = this.onLanguageMenuClose.bind(this);
    this.selectLanguage = this.selectLanguage.bind(this);
    this.hideBackToTop = this.hideBackToTop.bind(this);
    this.closeSnackbar = this.closeSnackbar.bind(this);
    this.activateKwezal = this.activateKwezal.bind(this);

    this.state = {
      screenSize: ScreenSize.EXTRA_SMALL, // Snap uses 480 width browser
      backToTopVisible: false,
      snackbarOpen: false,
      snackbarText: "",
      retroMode: false,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize);
    window.addEventListener("keydown", this.onKeyDown);
    this.scrollbaleContentRef.current?.addEventListener(
      "scroll",
      this.onContentScroll
    );
    window.addEventListener("load", this.onLoad);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("keydown", this.onKeyDown);
    this.scrollbaleContentRef.current?.removeEventListener(
      "scroll",
      this.onContentScroll
    );
    window.removeEventListener("load", this.onLoad);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.location !== prevProps.location) {
      this.props.onRouteChange(this.props.location.pathname);
    }
  }

  render() {
    setLanguage(str);

    const dark = this.props.darkTheme;
    let themes = ["dark", "light"];
    if (!dark) {
      themes.reverse();
    }

    document.body.classList.toggle(themes[0], true);
    document.body.classList.toggle(themes[1], false);

    const theme = AppThemes.byId(`${getRouteId(getRoute())}-${themes[0]}`);

    return (
      <ThemeProvider theme={theme.get(false)}>
        <React.Suspense fallback={<></>}>
          {!this.state.retroMode ? null : <RetroMode />}
        </React.Suspense>

        <ParticlesBackground theme={theme} />
        <div
          id="app"
          style={{
            background: theme.get().palette.background.default,
          }}
        >
          <AppBar color="default" id="app-bar">
            <div className="toolbar-container">
              <Toolbar className="toolbar">
                <div id="toolbar-kwezal-logo" onClick={this.activateKwezal} />
                {this.createNavigation()}
                <div className="grow" />
                <IconButton onClick={this.openLanguageMenu} color="primary">
                  <span className="flag-button">
                    {
                      (
                        Language.withCode(getLanguageCode() || "") ||
                        Language.ENGLISH_US
                      ).flag
                    }
                  </span>
                </IconButton>
                <IconButton onClick={this.switchTheme}>
                  {theme.isDark ? (
                    <WbSunnyIcon htmlColor={SUN_COLOR} />
                  ) : (
                    <Brightness3Icon htmlColor={MOON_COLOR} />
                  )}
                </IconButton>
              </Toolbar>
            </div>
          </AppBar>
          <div className={`${getRouteId(getRoute())} content`}>
            <div
              className="readable-content-container"
              ref={this.scrollbaleContentRef}
            >
              <Box className="centered-content-container" p={2}>
                <span id="top" />
                <RouterOutlet location={this.props.location}>
                  <Route path={createNewRoute(ROUTE_PRODUCTS)}>
                    <ProductsView screenSize={this.state.screenSize} />
                  </Route>
                  <Route path={createNewRoute(ROUTE_FRIENDS)}>
                    <FriendsView />
                  </Route>
                  <Route path={createNewRoute(ROUTE_CONTACT)}>
                    <ContactView
                      screenSize={this.state.screenSize}
                      darkTheme={dark}
                    />
                  </Route>
                  <Route
                    path={createNewRoutes(
                      ROUTE_NEST,
                      ROUTE_BLOG,
                      ROUTE_PHILOSOPHY,
                      ROUTE_TEAM
                    )}
                  >
                    <HomeView
                      darkTheme={dark}
                      screenSize={this.state.screenSize}
                    />
                  </Route>
                </RouterOutlet>
              </Box>
            </div>
          </div>
          <BackToTop
            visible={this.state.backToTopVisible}
            onClick={this.hideBackToTop}
          />
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.snackbarOpen}
          message={this.state.snackbarText}
          autoHideDuration={SNACKBAR_DURATION}
          onClose={this.closeSnackbar}
        />
        <Menu
          anchorEl={this.state.navigationMenuAnchor}
          keepMounted
          open={this.state.navigationMenuAnchor !== undefined}
          onClose={this.onNavigationMenuClose}
        >
          <nav>{this.createNavigationMenuItems()}</nav>
        </Menu>
        <Menu
          anchorEl={this.state.languageMenuAnchor}
          keepMounted
          open={this.state.languageMenuAnchor !== undefined}
          onClose={this.onLanguageMenuClose}
        >
          <LanguageMenuItems
            route={getRoute()}
            onSelect={this.selectLanguage}
          />
        </Menu>
      </ThemeProvider>
    );
  }

  private createNavigation() {
    const navItems = MainContainer.getNavigationItems();

    const routeId = getMainRouteId(getRoute());
    return (
      <nav>
        <Button
          key={-1}
          startIcon={<MenuIcon color="action" />}
          className={`selected menu ${getRouteId()} navigation-item`}
          onClick={this.openNavigationMenu}
        >
          {navItems.find((item) => item.route === routeId)?.text}
        </Button>
        {navItems.map((item, i) => {
          const selected = item.route === routeId;
          const fullRoute = createNewRoute(item.route);
          return (
            <React.Fragment key={i}>
              <Button
                component={RouterLink}
                to={fullRoute}
                replace
                className={`${selected ? "selected " : ""}${getRouteId(
                  fullRoute
                )} navigation-item`}
                size="small"
                disabled={selected}
              >
                {item.text}
              </Button>
              {i !== navItems.length - 1 && (
                <span className="separator"> / </span>
              )}
            </React.Fragment>
          );
        })}
      </nav>
    );
  }

  private createNavigationMenuItems() {
    const routeId = getMainRouteId(getRoute());
    const navItems = MainContainer.getNavigationItems();
    return navItems.map((item, i) => {
      const selected = item.route === routeId;
      const fullRoute = createNewRoute(item.route);
      return (
        <MenuItem
          key={i}
          component={RouterLink}
          to={fullRoute}
          replace
          className={`${selected ? "selected " : ""}${getRouteId(
            fullRoute
          )} navigation-menu-item`}
          disabled={selected}
          onClick={this.onNavigationMenuClose}
        >
          {item.text}
        </MenuItem>
      );
    });
  }

  private onLoad() {
    const screenSize = MainContainer.getScreenSize(window.innerWidth);
    if (screenSize !== this.state.screenSize) {
      this.setState({ screenSize: screenSize });
    }
  }

  private openNavigationMenu(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    this.setState({ navigationMenuAnchor: e.currentTarget });
  }

  private onNavigationMenuClose() {
    this.setState({ navigationMenuAnchor: undefined });
  }

  private static getNavigationItems(): NavigationItemInfo[] {
    return [
      { text: str.productsViewTitle, route: ROUTE_PRODUCTS },
      { text: str.friendsViewTitle, route: ROUTE_FRIENDS },
      { text: str.contactViewTitle, route: ROUTE_CONTACT },
      { text: str.homeViewTitle, route: ROUTE_NEST },
    ];
  }

  private openLanguageMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.setState({ languageMenuAnchor: e.currentTarget });
  }

  private onLanguageMenuClose() {
    this.setState({ languageMenuAnchor: undefined });
  }

  private selectLanguage(language: Language) {
    str.setLanguage(language.code);
    this.onLanguageMenuClose();
  }

  private updateScreenSize(width: number) {
    const screenSize = MainContainer.getScreenSize(width);
    if (screenSize !== this.state.screenSize) {
      this.setState({ screenSize: screenSize });
    }
  }

  private static getScreenSize(width: number): ScreenSize {
    if (width >= breakpoints.screenXl) return ScreenSize.EXTRA_LARGE;
    if (width >= breakpoints.screenLg) return ScreenSize.LARGE;
    if (width >= breakpoints.screenMd) return ScreenSize.MEDIUM;
    if (width >= breakpoints.screenSm) return ScreenSize.SMALL;
    else return ScreenSize.EXTRA_SMALL;
  }

  private hideBackToTop() {
    this.setState({ backToTopVisible: false });
  }

  private updateBackToTopButton(scrollTop: number) {
    if (this.lastScroll < SCROLL_THRESHOLD && scrollTop >= SCROLL_THRESHOLD) {
      this.lastScroll = scrollTop;
      this.setState({ backToTopVisible: true });
    } else if (
      this.lastScroll >= SCROLL_THRESHOLD &&
      scrollTop < SCROLL_THRESHOLD
    ) {
      this.lastScroll = scrollTop;
      this.setState({ backToTopVisible: false });
    }
  }

  private switchTheme() {
    this.props.onThemeChange(!this.props.darkTheme);
  }

  private closeSnackbar() {
    this.setState({ snackbarOpen: false });
  }

  private activateKwezal(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const kwezal = e.target as HTMLDivElement;
    const isActivated = kwezal.classList.contains("activated");
    let isPerfect = false;
    if (isActivated) {
      kwezal.classList.remove("activated", "perfect");
    } else {
      kwezal.classList.add("activated");

      isPerfect = Math.random() > 0.75;
      if (isPerfect) {
        kwezal.classList.add("perfect");
      }
    }

    this.setState({
      snackbarOpen: true,
      snackbarText: isActivated
        ? "Kwezal Deactivated"
        : isPerfect
        ? "Perfect Kwezal Activated"
        : "Kwezal Activated",
    });
  }
}
