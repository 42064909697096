import { Location } from "history";
import React, { Component, HTMLAttributes } from "react";
import { Switch } from "react-router";

interface Props extends HTMLAttributes<HTMLElement> {
  location?: Location;
  animationName?: string;
}

interface State {}

export default class RouterOutlet extends Component<Props, State> {
  render() {
    return (
      <div className={this.props.className}>
        {/* <TransitionGroup>
          <CSSTransition
            classNames={this.props.animationName || ""}
            timeout={500 /* TODO SASS VARIABLE */
        /*}
            key={this.props.location?.key || this.props.location?.pathname}
          > */}
        <Switch location={this.props.location}>{this.props.children}</Switch>
        {/* </CSSTransition>
        </TransitionGroup> */}
      </div>
    );
  }
}
