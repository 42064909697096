import React from "react";
import { Component } from "react";
import Particles from "react-tsparticles";
import { AppTheme } from "../theme/Theme";

interface Props {
  theme: AppTheme;
}

interface State {}

export default class ParticlesBackground extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  shouldComponentUpdate(newProps: Props) {
    return newProps.theme !== this.props.theme;
  }

  render() {
    const theme = this.props.theme.get();
    const bg = theme.palette.background.default;
    const fg = theme.palette.secondary.main;

    return (
      <Particles
        id="particles"
        options={{
          background: {
            color: {
              value: bg,
            },
          },
          fpsLimit: 30,
          interactivity: {
            detectsOn: "canvas",
            events: {
              resize: false,
            },
            modes: {
              bubble: {
                distance: 100,
                duration: 2,
                opacity: 0.8,
                size: 40,
              },
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 100,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: fg,
            },
            links: {
              color: fg,
              distance: 300,
              enable: true,
              opacity: 0.5,
              width: 2,
            },
            collisions: {
              enable: false,
            },
            move: {
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: false,
              speed: 0.5,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                value_area: 500,
              },
              value: 35,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              random: true,
              value: 2,
            },
          },
          detectRetina: true,
        }}
      />
    );
  }
}
