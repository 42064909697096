import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Icon,
  IconButton,
  Link,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { Component } from "react";
import ConstantComponent from "../../component/ConstantComponent";
import ProductPlatform from "./ProductPlatform";
import { localStr as productsViewStr } from "./ProductsView";
import { TechInfo } from "./Technology";

export type ProductInfo = {
  icon?: string;
  name: string;
  shortDescription: string;
  description: string;
  releaseYear: number;
  platforms?: ProductPlatform[];
  techStack: TechInfo[];
};

interface Props {
  product: ProductInfo;
  expanded: boolean;
  onChange: (expanded: boolean) => void;
}

interface State {}

export default class ProductCard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.expandCollapse = this.expandCollapse.bind(this);
  }

  shouldComponentUpdate(newProps: Props, newState: State) {
    return (
      newProps.expanded !== this.props.expanded ||
      newProps.product !== this.props.product
    );
  }

  render() {
    return (
      <Accordion expanded={this.props.expanded} onChange={this.expandCollapse}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item>
              <Box
                mr={2}
                className="product-image"
                style={{
                  backgroundImage:
                    this.props.product.icon === undefined
                      ? undefined
                      : `url(/graphics/${this.props.product.icon})`,
                }}
              />
            </Grid>
            <Grid item>
              <Box>
                <Typography
                  component="h2"
                  variant="h6"
                  color="secondary"
                  className="bold"
                >
                  {this.props.product.name}
                </Typography>
                <Typography component="h3" variant="body2">
                  {this.props.product.shortDescription}
                </Typography>
              </Box>
            </Grid>
            <div className="grow" />
            <Grid item>
              <PlatformLinks platforms={this.props.product.platforms || []} />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <ProductDescription description={this.props.product.description} />
            <TechIcons techStack={this.props.product.techStack} />
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }

  private expandCollapse(e: any, expanded: boolean) {
    this.props.onChange(expanded);
  }
}

interface PlatformLinksProps {
  platforms: ProductPlatform[];
}

class PlatformLinks extends ConstantComponent<PlatformLinksProps> {
  render() {
    return (
      <>
        {this.props.platforms.map((platform, i) => (
          <IconButton
            key={i}
            component={Link}
            href={platform.url}
            target="_blank"
          >
            {platform.icon}
          </IconButton>
        ))}
      </>
    );
  }
}

interface ProductDescriptionProps {
  description: string;
}

class ProductDescription extends Component<ProductDescriptionProps> {
  shouldComponentUpdate(newProps: ProductDescriptionProps) {
    return newProps.description !== this.props.description;
  }

  render() {
    return (
      <>
        {this.props.description.split("\n").map((line, i) => (
          <Typography className="justify" key={i}>
            {line !== "" ? line : "\u00A0"}
          </Typography>
        ))}
      </>
    );
  }
}

interface TechIconsProps {
  techStack: TechInfo[];
}

class TechIcons extends ConstantComponent<TechIconsProps> {
  render() {
    return (
      <>
        <Typography variant="h6" className="bold">
          {productsViewStr.techStack}
        </Typography>
        {this.props.techStack.map((technology, i) => (
          <Icon key={i} fontSize={"large"}>
            {technology.icon}{" "}
          </Icon>
        ))}
      </>
    );
  }
}
