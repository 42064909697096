import { Fab, Zoom } from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React, { Component } from "react";
import "./BackToTop.scss";

interface Props {
  visible: boolean;
  onClick?: () => void;
}

interface State {}

export default class BackToTop extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.scrollTop = this.scrollTop.bind(this);
  }

  shouldComponentUpdate(newProps: Props) {
    return newProps.visible !== this.props.visible;
  }

  render() {
    return (
      <Zoom in={this.props.visible}>
        <Fab
          className="back-to-top"
          role="presentation"
          color="secondary"
          size="small"
          onClick={this.scrollTop}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </Zoom>
    );
  }

  private scrollTop(event: React.MouseEvent<HTMLButtonElement>) {
    const anchor = (
      (event.target as HTMLButtonElement).ownerDocument || document
    ).querySelector("#top");

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
      this.props.onClick && this.props.onClick();
    }
  }
}
