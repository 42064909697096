import React from "react";
import { Component } from "react";
import ProductCard, { ProductInfo } from "./ProductCard";

interface Props {
  products: ProductInfo[];
}

interface State {
  expandedProduct: number;
}

export default class ProductCards extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { expandedProduct: 0 };
  }

  shouldComponentUpdate(newProps: Props, newState: State) {
    return (
      newState.expandedProduct !== this.state.expandedProduct ||
      newProps.products !== this.props.products
    );
  }

  render() {
    return this.props.products.map((product, i) => (
      <ProductCard
        key={i}
        product={product}
        expanded={i === this.state.expandedProduct}
        onChange={(expanded) =>
          expanded && this.setState({ expandedProduct: i })
        }
      />
    ));
  }
}
