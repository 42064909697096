import {
  Box,
  ButtonBase,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import GitHubIcon from "@material-ui/icons/GitHub";
import LanguageIcon from "@material-ui/icons/Language";
import TwitterIcon from "@material-ui/icons/Twitter";
import React, { Component } from "react";
import LocalizedStrings from "react-localization";
import { Link as RouterLink } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import {
  createNewRoute,
  getRoute,
  getSectionName,
  hasLanguageChanged,
  setLanguage,
} from "../../../component/ComponentUtils";
import TabPanel from "../../../component/TabPanel";
import {
  ROUTE_NEST,
  ROUTE_TEAM,
  ROUTE_TEAM_ARTI,
  ROUTE_TEAM_MASTERMIND,
} from "../../../Routes";
import colors from "../../../style/Colors.scss";
import "./TeamView.scss";

const localStr = new LocalizedStrings({
  en: {
    descriptionArti:
      "His peculiar anonimity and privacy in public space caused that Kwezal cast a curse on him as a result of which he bacame a robocat afflicted with heterochromia and being the mascot of the company. He exists in his cosmic world of fractals and feeds on the surreal matter of pure abstraction. It is believed that in his free time from creating graphics, he plans to take control of the world.",
    descriptionMastermind:
      "He has been programming for as long as he can remember and his memory is not too bad; Functions at the highest level of abstraction; Ends each sentence with a semicolon; Workaholic who is at odds with Hypnos; They say apps are not the only thing he writes;",
  },
  pl: {
    descriptionArti:
      "Jego osobliwa anonimowość i prywatność w przestrzeni publicznej sprawiła, że Kwezal rzucił na niego klątwę, wskutek której stał się robokotem dotkniętym heterochromią będącym maskotką firmy. Egzystuje w swoim kosmicznym świecie fraktali karmiąc się surrealistyczną materią złożoną z czystej abstrakcji. Przypuszcza się, że w czasie wolnym od tworzenia grafiki, knuje plany przejęcia kontroli nad światem.",
    descriptionMastermind:
      "Programuje, od kiedy pamięta, a pamięć ma nie najgorszą; funkcjonuje na najwyższym poziomie abstrakcji; każde zdanie kończy średnikiem; skłócony z Hypnosem pracoholik; powiadają, że pisze nie tylko aplikacje;",
  },
});

type MemberLink = { icon: any; url: string; name: string };

type MemberInfo = {
  name: string;
  description: string;
  route: string;
  avatar: AvatarInfo;
  links?: MemberLink[];
};

type AvatarInfo = { fileName: string; hueDeg: number; borderColor: string };

interface Props {
  darkTheme: boolean;
}

interface State {
  memberIndex: number;
}

/* <MobileStepper
        key="stepper"
        variant="dots"
        steps={members.length}
        position="static"
        activeStep={this.state.memberIndex}
        nextButton={
          <Button
            size="small"
            component={RouterLink}
            replace
            onClick={() =>
              this.setState({ memberIndex: this.getNextMemberIndex(members) })
            }
          >
            {members[this.getNextMemberIndex(members)].name}
            {<KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button
            size="small"
            component={RouterLink}
            replace
            onClick={() =>
              this.setState({
                memberIndex: this.getPreviousMemberIndex(members),
              })
            }
          >
            {<KeyboardArrowLeft />}
            {members[this.getPreviousMemberIndex(members)].name}
          </Button>
        }
      /> */

export default class TeamView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.setMemberIndex = this.setMemberIndex.bind(this);

    this.state = {
      memberIndex: this.getTabIndexByRoute(getRoute()),
    };
  }

  componentDidMount() {}

  shouldComponentUpdate(newProps: Props, newState: State) {
    return (
      newState.memberIndex !== this.state.memberIndex ||
      newProps.darkTheme !== this.props.darkTheme ||
      hasLanguageChanged(localStr)
    );
  }

  render() {
    setLanguage(localStr);

    const members = TeamView.getMembers();
    return (
      <>
        <Box>{this.createMemberAvatars(members)}</Box>
        <Box mb={2}>
          <SwipeableViews
            axis={"x"}
            index={this.state.memberIndex}
            onChangeIndex={this.setMemberIndex}
          >
            {this.createMemberDescriptions(members)}
          </SwipeableViews>
        </Box>
      </>
    );
  }

  /* private getNextMemberIndex(members: MemberInfo[]) {
    return (this.state.memberIndex + 1) % members.length;
  }

  private getPreviousMemberIndex(members: MemberInfo[]) {
    return this.state.memberIndex === 0
      ? members.length - 1
      : this.state.memberIndex - 1;
  } */

  private createMemberAvatars(members: MemberInfo[]) {
    return members.map((member, i) => {
      const avatar = member.avatar;
      return (
        <ButtonBase
          key={i}
          className="avatar-container"
          disabled={i === this.state.memberIndex}
          style={{
            borderColor:
              this.state.memberIndex !== i ? undefined : avatar.borderColor,
          }}
          onClick={() => this.setState({ memberIndex: i })}
        >
          <RouterLink
            to={createNewRoute(`${ROUTE_NEST}/${ROUTE_TEAM}/${member.route}`)}
            replace
          >
            <div
              className="avatar"
              style={{
                backgroundImage: `url(/graphics/${avatar.fileName})`,
                filter:
                  this.state.memberIndex !== i
                    ? this.props.darkTheme
                      ? undefined
                      : "brightness(0)"
                    : `brightness(0.5) sepia(1) hue-rotate(${avatar.hueDeg}deg) saturate(500%)`,
              }}
            ></div>
          </RouterLink>
        </ButtonBase>
      );
    });
  }

  private createMemberDescriptions(members: MemberInfo[]) {
    return members.map((member, i) => (
      <TabPanel key={i} visible={this.state.memberIndex === i}>
        <Box mx={1} my={1}>
          <Paper elevation={3}>
            <Box p={2}>
              <Typography variant="h6" component="h2" color="primary">
                {member.name}
              </Typography>
              <Typography className="justify">{member.description}</Typography>
              {member.links && (
                <List>
                  {member.links.map((link, i) => (
                    <ListItem
                      key={i}
                      color="inherit"
                      component={Link}
                      href={link.url}
                      target="_blank"
                      underline="none"
                      button
                    >
                      <ListItemIcon>{link.icon}</ListItemIcon>
                      <ListItemText primary={link.name} />
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
          </Paper>
        </Box>
      </TabPanel>
    ));
  }

  private setMemberIndex(i: number) {
    this.setState({ memberIndex: i });
  }

  private static getMembers(): MemberInfo[] {
    return [
      {
        name: "Mastermind",
        description: localStr.descriptionMastermind,
        route: ROUTE_TEAM_MASTERMIND,
        avatar: {
          fileName: "mastermind.png",
          hueDeg: 180,
          borderColor: colors.kwezalBlue,
        },
        links: [
          {
            icon: <EmailIcon />,
            url: "mailto:mastermind@kwezal.com",
            name: "mastermind@kwezal.com",
          },
          {
            icon: <LanguageIcon />,
            url: "https://kajetansuchanski.pl",
            name: "kajetansuchanski.pl",
          },
          {
            icon: <TwitterIcon />,
            url: "https://twitter.com/kaj_suchanski",
            name: "kaj_suchanski",
          },
          {
            icon: <GitHubIcon />,
            url: "https://github.com/kajetan-suchanski",
            name: "kajetan-suchanski",
          },
        ],
      },
      {
        name: "Arti",
        description: localStr.descriptionArti,
        route: ROUTE_TEAM_ARTI,
        avatar: {
          fileName: "arti.png",
          hueDeg: -50,
          borderColor: colors.kwezalRed,
        },
      },
    ];
  }

  private getTabIndexByRoute(route: string) {
    switch (getSectionName(2, route)) {
      case ROUTE_TEAM_ARTI:
        return 1;
      default:
        return 0;
    }
  }
}
