export default class Language {
  readonly code: string;
  readonly name: string;
  readonly flag: string;

  constructor(code: string, name: string, flag: string) {
    this.code = code;
    this.name = name;
    this.flag = flag;
  }

  static withCode(code: string) {
    return [this.ENGLISH_US /*, this.ENGLISH_UK*/, this.POLISH].find(
      (language) => language.code === code
    );
  }

  static readonly ENGLISH_US = new Language("en", "English (US)", "🇺🇸");
  // static readonly ENGLISH_UK = new Language("en", "English (UK)", "🇬🇧");
  static readonly POLISH = new Language("pl", "Polski", "🇵🇱");
}
