import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  MobileStepper,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import React, { Component } from "react";
import LocalizedStrings from "react-localization";
import SwipeableViews from "react-swipeable-views";
import MediumIcon from "../../../icon/MediumIcon";
import xml2js from "xml2js";
import "./BlogView.scss";
import TimeUtils from "../../../time/TimeUtils";
import colors from "../../../style/Colors.scss";
import AppCache from "../../../cache/AppCache";
import {
  hasLanguageChanged,
  setLanguage,
} from "../../../component/ComponentUtils";
import TabPanel from "../../../component/TabPanel";

export const BLOG_URL = "https://medium.com/@KajetanSuchanski";
const BLOG_FEED_URL = "https://medium.com/feed/@KajetanSuchanski";
const CORS_ANYWHERE_URL = "https://cors.bridged.cc";

const POSTS_LIMIT = 3;

const localStr = new LocalizedStrings({
  en: {
    allPosts: "All",
    description:
      "Kwezal's Mastermind runs a technology blog where he shares his programming experience, insights and conclusions. Moreover, on this blog he promotes Kwezal products and services. If you're into technology and programming, check it out and maybe you will find valuable content for yourself.",
    latestArticles: "Latest blog articles",
    newerPosts: "Newer",
    olderPosts: "Older",
  },
  pl: {
    allPosts: "Wszystkie",
    description:
      "Mastermind Kwezal prowadzi bloga technologicznego, gdzie dzieli się programistycznym doświadczeniem, spostrzeżeniami i wnioskami, a także promuje produkty oraz usługi Kwezala. Jeżeli interesuje Cię technologia i programowanie, a język angielski nie jest Ci straszny, zajrzyj tam, a może znajdziesz wartościową treść dla siebie.",
    latestArticles: "Najnowsze artykuły na blogu",
    newerPosts: "Nowsze",
    olderPosts: "Starsze",
  },
});

type FeedPost = {
  link: string[];
  title: string[];
  description: string[];
  pubDate: string[];
};

export type Post = {
  url?: string;
  title?: string;
  description?: string;
  imageUrl?: string;
  publicationTimestamp?: number;
};

interface Props {
  darkTheme: boolean;
}

interface State {
  posts: Post[];
  postIndex: number;
}

export default class BlogView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.setPostIndex = this.setPostIndex.bind(this);
    this.incrementPostIndex = this.incrementPostIndex.bind(this);
    this.decrementPostIndex = this.decrementPostIndex.bind(this);

    this.state = {
      posts: [],
      postIndex: 0,
    };
  }

  componentDidMount() {
    this.fetchBlogFeed();
  }

  shouldComponentUpdate(newProps: Props, newState: State) {
    return (
      newState.postIndex !== this.state.postIndex ||
      newState.posts.length !== this.state.posts.length ||
      newProps.darkTheme !== this.props.darkTheme ||
      hasLanguageChanged(localStr)
    );
  }

  render() {
    setLanguage(localStr);

    return (
      <>
        <Box mb={2}>
          <Typography className="justify">{localStr.description}</Typography>
        </Box>
        <ListItem
          color="inherit"
          component={Link}
          href={BLOG_URL}
          target="_blank"
          underline="none"
          button
        >
          <ListItemIcon>
            <SvgIcon
              htmlColor={this.props.darkTheme ? undefined : colors.mediumColor}
            >
              {MediumIcon}
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary={"Medium"} />
        </ListItem>
        <Divider />
        <Box my={2}>
          <Typography
            className="bold"
            variant="h6"
            component="h2"
            color="secondary"
          >
            {localStr.latestArticles}
          </Typography>
        </Box>
        <SwipeableViews
          axis={"x"}
          index={this.state.postIndex}
          onChangeIndex={this.setPostIndex}
        >
          {this.createPostLinks()}
        </SwipeableViews>
        <MobileStepper
          variant="dots"
          steps={this.state.posts.length}
          position="static"
          activeStep={this.state.postIndex}
          nextButton={
            <Button
              size="small"
              onClick={
                this.state.postIndex >= this.state.posts.length - 1
                  ? () => {
                      window.open(BLOG_URL, "_blank");
                    }
                  : this.incrementPostIndex
              }
            >
              <Typography className="lower-case">
                {this.state.postIndex >= this.state.posts.length - 1
                  ? localStr.allPosts
                  : localStr.olderPosts}
              </Typography>
              {<KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={this.decrementPostIndex}
              disabled={this.state.postIndex === 0}
            >
              {<KeyboardArrowLeft />}
              <Typography className="lower-case">
                {localStr.newerPosts}
              </Typography>
            </Button>
          }
        />
      </>
    );
  }

  private createPostLinks() {
    return this.state.posts.map((post, i) => (
      <TabPanel key={i} visible={this.state.postIndex === i}>
        <Card component={"article"}>
          <Link className="blog-post-link" href={post.url} target="_blank">
            <CardActionArea>
              <CardMedia
                className="blog-post-image-container"
                image={post.imageUrl}
              />
              <CardContent className="blog-post-content-container">
                <Typography
                  variant="caption"
                  color="textSecondary"
                  component="p"
                >
                  {post.publicationTimestamp === undefined
                    ? undefined
                    : TimeUtils.formatLongDate(post.publicationTimestamp, true)}
                </Typography>
                <Typography gutterBottom variant="h6" component="h3">
                  {post.title}
                </Typography>
                <Typography variant="body2" color="textPrimary" component="p">
                  {post.description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Link>
        </Card>
      </TabPanel>
    ));
  }

  private incrementPostIndex() {
    this.setPostIndex(this.state.postIndex + 1);
  }

  private decrementPostIndex() {
    this.setPostIndex(this.state.postIndex - 1);
  }

  private setPostIndex(i: number) {
    this.setState({ postIndex: i });
  }

  private async fetchBlogFeed() {
    let posts: Post[] = [];
    if (AppCache.blogPosts.length !== 0) {
      posts = AppCache.blogPosts;
    } else {
      try {
        const response = await fetch(`${CORS_ANYWHERE_URL}/${BLOG_FEED_URL}`);
        const responseText = await response.text();
        const responseJson = await xml2js.parseStringPromise(responseText);
        const feedPosts = responseJson.rss.channel[0].item as FeedPost[];

        for (let i = 0; i < Math.min(feedPosts.length, POSTS_LIMIT); ++i) {
          const feedPost = feedPosts[i];

          const descriptionDocument = new DOMParser().parseFromString(
            feedPost.description[0],
            "text/html"
          );

          const description =
            (
              descriptionDocument.querySelector(
                ".medium-feed-snippet"
              ) as HTMLParagraphElement
            ).textContent || "";

          const imageUrl = (
            descriptionDocument.querySelector(
              ".medium-feed-image img"
            ) as HTMLImageElement
          ).src;

          const dateText = feedPost.pubDate[0];

          const post = {
            url: feedPost.link[0],
            title: feedPost.title[0],
            description: description,
            imageUrl: imageUrl,
            publicationTimestamp: Date.parse(dateText),
          };

          posts.push(post);
        }

        AppCache.blogPosts = posts;
      } catch (e) {
        console.error(e);
      }
    }

    this.setState({ posts: posts });
  }
}
