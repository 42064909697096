import React, { Component } from "react";
import LocalizedStrings from "react-localization";
import { Redirect, Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import {
  getLanguageCode,
  getRoute,
  setLanguage,
} from "./component/ComponentUtils";
import MainContainer from "./component/MainContainer";
import Language from "./language/Language";
import { capitalizeFirstLetter } from "./utils/StringUtils";

const localStr = new LocalizedStrings({
  en: {
    descriptionContact:
      "Kwezal contact page. Get in touch with Kwezal by email or get to know us better on company social media profiles.",
    descriptionFriends:
      "List of Kwezal friends, contributors and tips of how to become one of them.",
    descriptionNest:
      "Welcome to the Kwezal Nest home page! The official website for Kwezal, developer of mobile apps, games, websites, web applications and open-source software.",
    descriptionNestAbout:
        "All official information about Kwezal company. Milestones in Kwezal history, released games, applications, programs and other software projects.",
    descriptionNestBlog:
        "Technology blog run by Kwezal Mastermind. Articles and posts about programming, design, software architecture and engineering.",
    descriptionNestTeam:
        "Kwezal team members. Avatars and descriptions of all people involved in Kwezal development. Programmers and graphic designers working for the company.",
    descriptionProducts:
      "List of all released Kwezal products and services. Published games, apps, websites, web applications and open-source software projects.",
    titleContact: "Contact",
    titleContactDescription: "Contact us, get to know us better",
    titleFriends: "Friends",
    titleFriendsDescription: "Contributors, friends, how to become one",
    titleNest: "Nest",
    titleNestAbout: "About us and our history",
    titleNestBlog: "Blog",
    titleNestDescription: "Time for Freedom and Creativity",
    titleNestTeam: "Team",
    titleTeamArti: "Arti",
    titleTeamMastermind: "Mastermind",
    titleProducts: "Products",
    titleProductsApps: "Mobile apps & games",
    titleProductsDescription: "Apps, games, websites, open-source",
    titleProductsOpensource: "Open-source software",
    titleProductsWebsites: "Websites & web applications",
  },
  pl: {
    descriptionContact:
      "Strona kontaktowa Kwezala. Nawiąż kontakt z Kwezalem przez e-mail lub poznaj nas lepiej na profilach w portalach społecznościowych.",
    descriptionFriends:
      "Lista przyjaciół i współpracowników Kwezala oraz porady jak zostać jednym z nich.",
    descriptionNest:
      "Witaj na stronie domowej Gniazdo Kwezala! Oficjalna wizytówka Kwezala, producenta mobilnych gier, aplikacji, stron internetowych i otwartego oprogramowania.",
    descriptionNestAbout:
        "Wszystkie oficjalne informacje o formie Kwezal. Kamienie milowe w historii Kwezala, wydane gry, aplikacje, programy i inne projekty oprogramowania.",
    descriptionNestBlog:
        "Blog technologiczny prowadzony przez Kwezalowego Masterminda. Artykuły i wpisy o programowaniu, projektowaniu, architekturze i inżynierii oprogramowania.",
    descriptionNestTeam:
        "Członkowie zespołu Kwezala. Awatary i opisy wszystkich ludzi związanych z rozwojem firmy Kwezal. Programiści i projektanci grafiki pracujący dla firmy.",
    descriptionProducts:
      "Lista wszystkich wydanych produktów i usług Kwezala. Opublikowane gry, aplikacje, strony internetowe i projekty wolnego oprogramowania (otwartoźródłowe).",
    titleContact: "Kontakt",
    titleContactDescription: "Skontaktuj się z nami i poznaj nas bliżej",
    titleFriends: "Przyjaciele",
    titleFriendsDescription:
      "Współpracownicy, przyjaciele i jak zostać jednym z nich",
    titleNest: "Gniazdo",
    titleNestAbout: "O nas i naszej historii",
    titleNestBlog: "Blog",
    titleNestDescription: "Time for Freedom and Creativity",
    titleNestTeam: "Zespół",
    titleTeamArti: "Arti",
    titleTeamMastermind: "Mastermind",
    titleProducts: "Produkty",
    titleProductsApps: "Mobilne gry i aplikacje",
    titleProductsDescription:
      "Gry, aplikacje, strony internetowe, otwarte oprogramowanie",
    titleProductsOpensource: "Oprogramowanie otwartoźródłowe",
    titleProductsWebsites: "Strony i aplikacje internetowe",
  },
});

interface Props {}

interface State {
  route: string;
  darkTheme: boolean;
}

export default class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.setRoute = this.setRoute.bind(this);
    this.changeTheme = this.changeTheme.bind(this);

    this.state = { route: getRoute(), darkTheme: true };
  }

  shouldComponentUpdate(newProps: Props, newState: State) {
    return (
      newState.route !== this.state.route ||
      newState.darkTheme !== this.state.darkTheme
    );
  }

  render() {
    const willRedirect = this.state.route.length <= 4;

    const languageCode = willRedirect
      ? localStr.getLanguage()
      : getLanguageCode(this.state.route) || Language.ENGLISH_US.code;

    document.documentElement.lang = languageCode;
    setLanguage(localStr, languageCode);

    const route = willRedirect
      ? `/${languageCode}/products`
      : this.state.route;

    App.setTitle(this.getTitle(route));
    App.setDescription(App.getDescription(route));

    return (
      <BrowserRouter>
        <Switch>
          <Route exact path={["/", "/pl/", "/en/"]}>
            <Redirect to={`/${languageCode}/products`} />
          </Route>
          <Route
            path={[`/en/`, `/pl/`]}
            render={(props) => (
              <MainContainer
                {...props}
                darkTheme={this.state.darkTheme}
                onThemeChange={this.changeTheme}
                onRouteChange={this.setRoute}
              />
            )}
          />
        </Switch>
      </BrowserRouter>
    );
  }

  private setRoute(route: string) {
    this.setState({ route: getRoute(route) });
  }

  private changeTheme(dark: boolean) {
    this.setState({ darkTheme: dark });
  }

  private static setTitle(title: string) {
    document.title = title;
    App.setMeta("og-title", title);
    App.setMeta("twitter-title", title);
  }

  private static setDescription(description: string) {
    App.setMeta("meta-description", description);
    App.setMeta("og-description", description);
    App.setMeta("twitter-description", description);
  }

  private static setMeta(id: string, content: string) {
    (document.getElementById(id) as HTMLMetaElement).content = content;
  }

  private getTitle(route: string): string {
    route = App.formatRoute(route);
    const parts: string[] =
      route === ""
        ? []
        : route
            .split("/")
            .map((part, i, arr) =>
              localStr.getString(
                `title${
                  i === 0 ? "" : capitalizeFirstLetter(arr[i - 1])
                }${capitalizeFirstLetter(part)}`
              )
            )
            .reverse();

    if (parts.length === 1) {
      const description = localStr.getString(
        `title${capitalizeFirstLetter(route)}Description`
      );
      if (description !== null) {
        parts.push(description);
      }
    }

    return parts.length !== 0 ? App.concatTitle(parts) : localStr.titleNest;
  }

  private static concatTitle(parts: string[]): string {
    return `${parts.join(" - ")} | Kwezal`;
  }

  private static getDescription(route: string) {
    route = App.formatRoute(route);
    const parts: string[] = route === "" ? [] : route.split("/");

    let description: string | undefined;
    for (let i = parts.length - 1; i >= 0; --i) {
      const partDescription = localStr.getString(
        `description${
          i === 0 ? "" : capitalizeFirstLetter(parts[i - 1])
        }${capitalizeFirstLetter(parts[i])}`
      );

      if (partDescription !== null) {
        description = partDescription;
        break;
      }
    }

    return description !== undefined ? description : localStr.descriptionNest;
  }

  private static formatRoute(route: string) {
    const len = route.length;
    if (len <= 4) {
      return "";
    }

    if (route.charAt(len - 1) === "/") {
      return route.substring(4, len - 1);
    } else {
      return route.substring(4);
    }
  }

  private static getPathParam(route: string) {
    const urlParams = new URLSearchParams(route);
    return urlParams.get("p") || "";
  }
}
