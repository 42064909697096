import { Box } from "@material-ui/core";
import React, { Component } from "react";
import "./BackToTop.scss";
import SectionHeader, { SectionHeaderInfo } from "./SectionHeader";

export const SECTIONS_COLOR = "primary";

export type SectionInfo = {
  headerInfo: SectionHeaderInfo;
  contentView: any;
};

interface Props {
  section: SectionInfo;
}

interface State {}

export default class Section extends Component<Props, State> {
  shouldComponentUpdate(newProps: Props) {
    return (
      newProps.section.headerInfo.title !==
        this.props.section.headerInfo.title ||
      newProps.section.contentView !== this.props.section.contentView
    );
  }

  render() {
    return (
      <>
        <SectionHeader header={this.props.section.headerInfo} />
        <Box p={2}>{this.props.section.contentView}</Box>
      </>
    );
  }
}
