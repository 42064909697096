import React, { Component } from "react";
import LocalizedStrings from "react-localization";
import {
  hasLanguageChanged,
  setLanguage,
} from "../../../component/ComponentUtils";
import { ProductInfo } from "../ProductCard";
import ProductCards from "../ProductCards";
import { WebPlatform } from "../ProductPlatform";
import {
  TechMaterialUi,
  TechReact,
  TechSass,
  TechTypeScript,
} from "../Technology";

const localStr = new LocalizedStrings({
  en: {
    asumvaDescription:
      "Website for Anastasia Sumanova, a talented architect from Russia. The portfolio contains her drawings, graphics and oil paintings.",
    asumvaName: "Anastasia Sumanova's Portfolio",
    asumvaShortDescription:
      "Internet business card of the architect Anastasia Sumanova.",
    kwezalDescription:
      "The official website for Kwezal, developer of mobile apps, games, websites, web applications and open-source software.",
    kwezalName: "Kwezal website",
    kwezalShortDescription: "You're here",
  },
  pl: {
    asumvaDescription:
      "Strona internetowa dla Anastasii Sumanovej, utalentowanej, rosyjskiej architekt. Portoflio zawiera jej rysunki, grafiki i obrazy olejne.",
    asumvaName: "Portfolio Anastasii Sumanovej",
    asumvaShortDescription:
      "Wizytówka internetowa architekt Anastasii Sumanovej.",
    kwezalDescription:
      "Oficjalna wizytówka Kwezala, producenta mobilnych gier, aplikacji, stron internetowych i otwartego oprogramowania.",
    kwezalName: "Strona internetowa Kwezala",
    kwezalShortDescription: "Jesteś tutaj",
  },
});

interface Props {}

interface State {}

export default class WebsitesView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  shouldComponentUpdate(newProps: Props, newState: State) {
    return hasLanguageChanged(localStr);
  }

  render() {
    setLanguage(localStr);

    const products = WebsitesView.getProducts();
    return <ProductCards products={products} />;
  }

  private static getProducts(): ProductInfo[] {
    return [
      {
        icon: "asumva-logo-2020.png",
        name: localStr.asumvaName,
        shortDescription: localStr.asumvaShortDescription,
        description: localStr.asumvaDescription,
        releaseYear: 2020,
        platforms: [WebPlatform("https://asumva.com")],
        techStack: [TechTypeScript, TechSass],
      },
      {
        icon: "kwezal-logo-2020.png",
        name: localStr.kwezalName,
        shortDescription: localStr.kwezalShortDescription,
        description: localStr.kwezalDescription,
        releaseYear: 2018,
        platforms: [WebPlatform("https://kwezal.com")],
        techStack: [TechTypeScript, TechReact, TechMaterialUi, TechSass],
      },
    ];
  }
}
