import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

if (process.env.NODE_ENV === "production") {
  // Remove console logs from production
  console.log = () => {};
  console.info = () => {};
  console.warn = () => {};
  console.error = () => {};
} else {
  /* const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, {
    include: [
      /HomeView/,
      /ProjectsView/,
    ],
  }); */
}

const rootElement = document.getElementById("root")!;
if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    rootElement
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    rootElement
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
